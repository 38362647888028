import { isEmail, isLength } from "validator";
import messages from "../common/messages";

export default function validateInternalCrmForm({
    setupid,
    name
   }) {
    let errors = {};
    if (!setupid) {
        errors.setupid = messages["setupid.required"];
    } 
 
      if (!name) {
        errors.name = messages["namecrm.required"];
    }
   
    return errors; 
}