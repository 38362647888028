import React from 'react';
//import { Link } from "react-router-dom";

const ListRealEstate=(props)=>{
 // console.log("this list page ",props.agentsReciveData);
 return(

  //         <React.Fragment>
//           <div className="topnav">
//   <a className="activ" href="#home">Real Estate</a>
//   <div className='float-left'>  <input type="text" placeholder="Search.."/> </div>
 
//   <div className='float-right'> <Link to='../admin/addrealestate'> < button className="btn btn-success btn-lg float-right" onClick={()=>props.onAddClick()}>Add New</button></Link></div>
 
// </div>
// <table className="table table-striped">
//   <thead>
//     <tr>
//       <th scope="col">#</th>
//       <th scope="col">Name</th>
//       <th scope="col">Address</th>
//       <th scope="col">Contact</th>
//       <th scope="col">Email</th>
//       <th scope="col">Action</th>
//     </tr>
//   </thead>
//   <tbody>
//   {props.agentsReciveData && props.agentsReciveData.length > 0 ? (
//                   props.agentsReciveData.map((item,index) => (
//     <tr key ={index}>
//       <th scope="row">{item.id}</th>
//       <td>{item.agentName}</td>
//       <td>{item.agentAddress}</td>
//       <td>{item.agentContact}</td>
//       <td>{item.agentEmailId}</td>
//       <td> <button onClick={()=>props.onEditClick(item.id)}><Link to={`/admin/addrealestate/edit/${item.id}`} title="Edit" className="btn btn-sm btn-outline-dark">
//                         <i className="fal fa-pen" ></i></Link></button>
//             <button type="button" className="btn btn-danger"><i className="far fa-trash-alt"></i></button></td>
//     </tr>
//     ))
//      ) : (
//       <tr>
//         <td >
//           <div className="no-data">
//             {/* <img src="/assets/images/empty-box.svg" /> */}
//             <h5>No Data Found</h5>
//           </div>
//         </td>
//       </tr>
//     )}

//     {/* <tr>
//       <th scope="row">2</th>
//       <td>Jacob</td>
//       <td>Thornton</td>
//       <td>@fat</td>
//       <td>Mark</td>
//       <td>Otto</td>
//     </tr>
//     <tr>
//       <th scope="row">3</th>
//       <td>Larry</td>
//       <td>the Bird</td>
//       <td>@twitter</td>
//       <td>Mark</td>
//       <td>Otto</td>
//     </tr> */}
//   </tbody>
// </table>

//         </React.Fragment>
<>
<nav aria-label="breadcrumb">
<ol className="breadcrumb">
  <li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
  <li className="breadcrumb-item active" aria-current="page">Client Management</li>
</ol>
</nav>


<div className="card">
<div className="card-body">
<div className="d-flex align-items-center justify-content-between mb-3">
  <h5 className="hed01">Client Management</h5>
  {/* <input className="form-control serch_hed" type="searchText"
             value={props.searchText}
             onChange={(e) => {
               props.searchHandleChange(e);
             }}
              placeholder="Search.."/> */}
  {/* <Link to='../admin/addrealestate' className="btn btn-info rounded"><i className="fal fa-plus"></i> Add</Link> */}
</div>

<table className="table table-striped">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      {/* <th scope="col">Address</th>
      <th scope="col">Contact</th>
      <th scope="col">Email</th>
      <th scope="col">Action</th> */}
    </tr>
  </thead>
  <tbody>
  <tr>
  <th scope="row">1</th>
      <td>Bullrage</td>
</tr>

      {/* {props.agentsReciveData && props.agentsReciveData.length > 0 ? (
                  props.agentsReciveData.map((item,index) => (
    <tr key ={index}>
      <th scope="row">{item.id}</th>
      <td>{item.agentName}</td>
       <td>{item.agentAddress}</td>
       <td>{item.agentContact}</td>
       <td>{item.agentEmailId}</td>
      <td> <a href="#" onClick={()=>props.onEditClick(item.id)}><Link to={`/admin/addrealestate/edit/${item.id}`} title="Edit" className="btn btn-sm btn-outline-dark">
                         <i className="fal fa-pencil" ></i></Link></a>
             <a href='#'
             title="Remove" 
             onClick={() =>props.openDeleteModal(item.id,1)}
             data-toggle="modal"
             data-target="#detele-popup"
             ><i className="far fa-trash"></i></a></td>
     </tr>
    ))
     ) : (
      <tr>
        <td colSpan="6">
          <div className="no-data text-center">
            {/* <img src="/assets/images/empty-box.svg" />
            <h5>No Data Found</h5>
          </div>
        </td>
      </tr>
    )} */}





 


    {/* <tr>
      <td>2</td>
      <td>Ace Realty</td>
      <td>Anreps Real Estate , Century 21</td>
      <td>9876567811</td>
      <td>ace@gmail.com</td>
      <td><a href="#"><i className="fal fa-pencil"></i></a> &nbsp;&nbsp;<a href="#"><i className="far fa-trash"></i></a></td>
    </tr>
    <tr>
      <td>3</td>
      <td>Ace Realty</td>
      <td>Anreps Real Estate , Century 21</td>
      <td>9876567811</td>
      <td>ace@gmail.com</td>
      <td><a href="#"><i className="fal fa-pencil"></i></a> &nbsp;&nbsp;<a href="#"><i className="far fa-trash"></i></a></td>
    </tr>
    <tr>
      <td>4</td>
      <td>Ace Realty</td>
      <td>Anreps Real Estate , Century 21</td>
      <td>9876567811</td>
      <td>ace@gmail.com</td>
      <td><a href="#"><i className="fal fa-pencil"></i></a> &nbsp;&nbsp;<a href="#"><i className="far fa-trash"></i></a></td>
    </tr>
    <tr>
      <td>1</td>
      <td>Ace Realty</td>
      <td>Anreps Real Estate , Century 21</td>
      <td>9876567811</td>
      <td>ace@gmail.com</td>
      <td><a href="#"><i className="fal fa-pencil"></i></a> &nbsp;&nbsp;<a href="#"><i className="far fa-trash"></i></a></td>
    </tr>
    <tr>
      <td>5</td>
      <td>Ace Realty</td>
      <td>Anreps Real Estate , Century 21</td>
      <td>9876567811</td>
      <td>ace@gmail.com</td>
      <td><a href="#"><i className="fal fa-pencil"></i></a> &nbsp;&nbsp;<a href="#"><i className="far fa-trash"></i></a></td>
    </tr>
    <tr>
      <td>1</td>
      <td>Ace Realty</td>
      <td>Anreps Real Estate , Century 21</td>
      <td>9876567811</td>
      <td>ace@gmail.com</td>
      <td><a href="#"><i className="fal fa-pencil"></i></a> &nbsp;&nbsp;<a href="#"><i className="far fa-trash"></i></a></td>
    </tr>
    <tr>
      <td>6</td>
      <td>Ace Realty</td>
      <td>Anreps Real Estate , Century 21</td>
      <td>9876567811</td>
      <td>ace@gmail.com</td>
      <td><a href="#"><i className="fal fa-pencil"></i></a> &nbsp;&nbsp;<a href="#"><i className="far fa-trash"></i></a></td>
    </tr> */}
  </tbody>
</table>
</div>
</div>
              
 </>
    );
                        
      }
export default ListRealEstate;