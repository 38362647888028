import * as Api from "./ApiRequest";
import * as path from "./ApiList";

 
export const addRealEstate = function(...params){
    return Api.postRequest(path.AGENTS_ADD_URL,params[0]);
}

// export const fetchRealAgentsList = function(...params){
//     return Api.getRequest(path.AGENTS_LIST_URL,params[0]);
// }

export const updateRealEstate = function(...params){
    const URL = `${path.AGENTS_UPDATE_URL}/${params[0].id}`;
    return Api.postRequest(URL, params[0]);
}

export const deleteRealAgents = function (...params) {
   
    const URL = `${path.AGENTS_DELETE_URL}/${params[0].data.sp_id}`;
    return Api.getRequest(URL, params[0]);
    //return Api.getRequest(path.AGENTS_DELETE_URL,params[0]);
   };

   export const deleteThridParty = function (...params) {
   
    const URL = `${path.THIRD_PARTY_DELETE_URL}/${params[0].data.sp_id}`;
    return Api.getRequest(URL, params[0]);
    //return Api.getRequest(path.AGENTS_DELETE_URL,params[0]);
   };
//deleteRequest
export const fetchByidRealAgentsList = function(...params){
    const URL = `${path.AGENTS_UPDATE_GET_URL}/${params[0].data}`;
    return Api.getRequest(URL, params[0]);
}
export const fetchRealAgentsList1 = function (...params) {
   
    let URL = "";
    const { page,  searchText } = params[0];
    URL = `${path.AGENTS_LIST_URL}`;
    if (searchText) {
      URL = `${path.AGENTS_LIST_URL}${searchText}`;
    }else if(page) {
        URL = `${path.AGENTS_LIST_URL}&page=${page}`;
    }
    else{
        URL = `${path.AGENTS_LIST_URL}`
    }
    return Api.getRequest(URL);
  };




export const fetchThirdPartyList1 = function (...params) {
    let URL = "";
    const { page, searchText } = params[0];
    URL = `${path.THIRD_LIST_URL}`;
    if (searchText) {
      URL = `${path.THIRD_LIST_URL}${searchText}`;
    }else if(page) {
        URL = `${path.THIRD_LIST_URL}&page=${page}`;
    }
    else{
        URL = `${path.THIRD_LIST_URL}`
    }
    return Api.getRequest(URL);
  };

///Third Party started  

export const addThirdParty = function(...params){
    return Api.postRequest(path.THIRD_ADD_URL,params[0]);
}

export const fetchThirdPartyList = function(...params){
    return Api.getRequest(path.THIRD_LIST_URL,params[0]);
}

export const fetchRealAgentsOption = function(...params){
    return Api.getRequest(path.AGENTS_DROP_DAWN,params[0]);
}

export const updateThirdParty = function(...params){
    const URL = `${path.THIRD_PARTY_UPDATE_URL}/${params[0].id}`;
    return Api.postRequest(URL, params[0]);
}

export const fetchThirdPartyById = function(...params){
    const URL = `${path.THIRD_PARTY_UPDATE_GET_URL}/${params[0].data}`;
    return Api.getRequest(URL, params[0]);
}
export const fetchActionBySetup = function(...params){
    const URL = `${path.THIRD_PARTY_UPDATE_GET_URL}/${params[0]}`;
    return Api.getRequest(URL, params[0]);
}
/// Internal CRM

export const addInternal = function(...params){
    return Api.postRequest(path.INTERNAL_ADD_URL,params[0]);
}

export const fetchInternalList = function(...params){
    return Api.getRequest(path.INTERNAL_LIST_URL,params[0]);
}

export const updateInternal = function(...params){
    const URL = `${path.INTERNAL_UPDATE_URL}/${params[0].id}`;
    return Api.postRequest(URL, params[0]);
}

export const fetchInternalId = function(...params){
    const URL = `${path.INTERNAL_UPDATE_GET_URL}/${params[0].data}`;
    return Api.getRequest(URL, params[0]);
}


export const fetchInternalList1 = function (...params) {
    let URL = "";
    const { page,  searchText } = params[0];
    URL = `${path.INTERNAL_LIST_URL}`;
    if (searchText) {
      URL = `${path.INTERNAL_LIST_URL}${searchText}`;
    }else if(page) {
        URL = `${path.INTERNAL_LIST_URL}&page=${page}`;
    }
    else{
        URL = `${path.INTERNAL_LIST_URL}`
    }
    return Api.getRequest(URL);
  };

  export const deleteInternal = function (...params) {
  
    const URL = `${path.INTERNAL_DELETE_URL}/${params[0].data.sp_id}`;
    return Api.getRequest(URL, params[0]);
    //return Api.getRequest(path.AGENTS_DELETE_URL,params[0]);
   };

//CRM drop Dawn....

export const fetchCrmExternalOption = function(...params){
    return Api.getRequest(path.CRM_DROP_DAWN_EXTERNAL_URL,params[0]);
}

export const fetchCrmInternalOption = function(...params){
    return Api.getRequest(path.CRM_DROP_DAWN_INTERNAL_URL,params[0]);
}

// export const fetchHistoryLog = function(...params){
//     return Api.getRequest(path.HISTORY_LOG_API,params[0]);
// }


export const fetchHistoryLog = function (...params) {
   
    let URL = "";
    const { page, searchText ,connectorid,startdate,enddate} = params[0];
    URL = `${path.HISTORY_LOG_API}&connectorid=${connectorid}&startdate=${startdate}&enddate=${enddate}`;
    if (searchText) {
      URL = `${path.HISTORY_LOG_API}${searchText}&page=${page}&connectorid=${connectorid}&startdate=${startdate}&enddate=${enddate}`;
    }else if(page) {
        URL = `${path.HISTORY_LOG_API}&page=${page}&connectorid=${connectorid}&startdate=${startdate}&enddate=${enddate}`;
    }
    else if(page && searchText) {
        URL = `${path.HISTORY_LOG_API}${searchText}&page=${page}&connectorid=${connectorid}&startdate=${startdate}&enddate=${enddate}`;
    }
    else{
        URL = `${path.HISTORY_LOG_API}&connectorid=${connectorid}&startdate=${startdate}&enddate=${enddate}`
    }
    return Api.getRequest(URL);
  };

export const fetchHistoryDetailsLog = function(...params){
    const URL = `${path.HISTORY_LOG_DETAILS_API}${params[0].data}`;
    return Api.getRequest(URL, params[0]);
}

export const fetchConnectorDropDawn = function(...params){
    const URL = `${path.CONNECTOR_DROP_DAWN_URL}`;
    return Api.getRequest(URL, params[0]);
}

export const fetchClientDropDawn = function(...params){
    const URL = `${path.CRM_SETUP_DROP_DAWN_URL}`;
    return Api.getRequest(URL, params[0]);
}

export const triggerConnecter = function(...params){  
    const URL = `${path.CONNECTOR_TRIGGER_URL}`;
    return Api.postRequest2(URL, params[0]);
}

//addConnecterData

export const addConnecterData = function(...params){  
    const URL = `${path.CONNECTOR_ADD_URL}`;
    return Api.postRequest2(URL, params[0]);
}



export const fetchConnecterList = function (...params) {
    let URL = "";
    const { page, searchText } = params[0];
    URL = `${path.CONNECTOR_LIST_URL}`;
    if (searchText) {
      URL = `${path.CONNECTOR_LIST_URL}${searchText}`;
    }else if(page) {
        URL = `${path.CONNECTOR_LIST_URL}&sortkey=&sortvalue=&page=${page}&size=12`;
    }
    else{
        URL = `${path.CONNECTOR_LIST_URL}`
    }
    return Api.getRequest(URL);
  };

  export const fetchConnectorView = function(...params){
    const URL = `${path.CONNECTOR_VIEW_URL}${params[0].data}`;
    return Api.getRequest(URL, params[0]);
}

export const deleteconnecter = function (...params) { 
    console.log("this is params.....",params);
    const URL = `${path.CONNECTOR_DELETE_URL}`;
    return Api.getRequest(URL, params[0]);
    //return Api.getRequest(path.AGENTS_DELETE_URL,params[0]);
   };