import React, { Component} from 'react';
import { Link } from 'react-router-dom';

const AddRealEstate=(props)=>{
  const{
    agentname,
    address,
    contactnumber,
    emailid,
    address2,
  state,
  zipcode,
  status
  }=props.agentState
  return (
    <>
        {/* <div className="row mt-5">
            <div className="col-12">
                <h4 className="mb-3 border-bottom pb-2">Real Estate</h4>
            </div>
            <div className="col-sm-6 col-md-4 col-xl-4">
                <div className="form-group">
                    <label htmlFor="">Real Estate Name*</label>
                    <input type="text" className="form-control"
                        name="agentname" value={agentname}
                        onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                         />
                </div>
                {/* <div className="errorMsg">{props.errors["name"]}</div> 
            </div>

            <div className="col-sm-6 col-md-4 col-xl-4">
                <div className="form-group">
                    <label htmlFor="">Real Estate Address 1*</label>
                    <input type="text" className="form-control"
                        name="address" value={address}
                        onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                         />
                </div>
                {/* <div className="errorMsg">{props.errors["name"]}</div> 
            </div>
            <div className="col-sm-6 col-md-4 col-xl-4">
                <div className="form-group">
                    <label htmlFor="">Real Estate Address 2*</label>
                    <input type="text" className="form-control"
                        name="address2" value={address2}
                        onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                         />
                </div>
                {/* <div className="errorMsg">{props.errors["name"]}</div> 
            </div>
            <div className="col-sm-6 col-md-4 col-xl-4">
                <div className="form-group">
                    <label htmlFor="">Real Estate State*</label>
                    <input type="text" className="form-control"
                        name="state" value={state}
                        onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                         />
                </div>
                {/* <div className="errorMsg">{props.errors["name"]}</div> 
            </div>
            <div className="col-sm-6 col-md-4 col-xl-4">
                <div className="form-group">
                    <label htmlFor="">Real Estate Zipcode 1*</label>
                    <input type="text" className="form-control"
                        name="zipcode" value={zipcode}
                        onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                         />
                </div>
                {/* <div className="errorMsg">{props.errors["name"]}</div> 
            </div>
            <div className="col-sm-6 col-md-4 col-xl-4">
                <div className="form-group">
                    <label htmlFor="">Phone *</label>
                    <input type="text" className="form-control"
                        name="contactnumber" value={contactnumber}
                        onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                         />
                </div>
                {/* <div className="errorMsg">{props.errors["name"]}</div> *
            </div>

            <div className="col-sm-6 col-md-4 col-xl-4">
                <div className="form-group">
                    <label htmlFor="">Email*</label>
                    <input type="text" className="form-control"
                        name="emailid" value={emailid}
                        onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                         />
                </div>
                {/* <div className="errorMsg">{props.errors["name"]}</div> 
            </div>
           
        </div>

        <div className="form-group text-right">

            <button className="btn btn-primary btn-sm px-3 rounded btnNextTab"
                onClick={() => props.onAddRealEstate()}
            > <i className="fa fa-save"></i> Save
            </button>
        </div> */}
<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
						<li className="breadcrumb-item active" aria-current="page">Client Detail</li>
					</ol>
				</nav>
				<div className="card">
					<div className="card-body">
						<div className="d-flex align-items-center justify-content-between mb-1">
							<h5 className="hed01">Client Detail</h5>
							
						</div>	
						<div className="row el_gr">
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Client Name*</label>	
									<input className="form-control" type="text"
                                     name="agentname" value={agentname}
                                     onChange={(e) => props.onDataInputFieldHnadleChange(e)}/>
                                     <div className="errorMsg">{props.errors["agentname"]}</div>
								</div>
							</div>
							{/*	<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Real Estate Address 1</label>	
									<input className="form-control" type="text" 
                                    name="address" value={address}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    />
                                    {/* <div className="errorMsg">{props.errors["address"]}</div> 
								</div>
							</div>
						 <div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Real Estate Address 2</label>	
									<input className="form-control" type="text" name="address2" value={address2}
                        onChange={(e) => props.onDataInputFieldHnadleChange(e)}/>
                        {/* <div className="errorMsg">{props.errors["address2"]}</div> 
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Real Estate State</label>	
									<input className="form-control" type="text" name="state" value={state}
                        onChange={(e) => props.onDataInputFieldHnadleChange(e)}/>
                        {/* <div className="errorMsg">{props.errors["state"]}</div>
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Real Estate Zipcode </label>	
									<input className="form-control" type="text"  name="zipcode" value={zipcode}
                        onChange={(e) => props.onDataInputFieldHnadleChange(e)}/>
                        {/* <div className="errorMsg">{props.errors["zipcode"]}</div> 
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Phone </label>	
									<input className="form-control" type="text" name="contactnumber" value={contactnumber}
                        onChange={(e) => props.onDataInputFieldHnadleChange(e)}/>
                        {/* <div className="errorMsg">{props.errors["contactnumber"]}</div>
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Email</label>	
									<input className="form-control" type="text"name="emailid" value={emailid}
                        onChange={(e) => props.onDataInputFieldHnadleChange(e)}/>
                        {/* <div className="errorMsg">{props.errors["emailid"]}</div>  
								</div>
							</div> */}

                            <div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Status</label>	
									<select className="form-control"
                                    name="status" value={status}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    >
										<option value="1" key="Active">Active</option>
										<option value="0" key="Deactive">Deactive</option>
									</select>
									 {/* <div className="errorMsg">{props.errors["agentid"]}</div>  */}
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
							</div>
						</div>
					</div>
				</div>
				<div className="down_pan">
					<div className="text-right">
						<Link to="/admin/listrealestate"><button className="btn_cf dim"><i className="fa fa-times" aria-hidden="true">&nbsp;</i> Cancel</button></Link> &nbsp;
						<button className="btn_cf scss"  onClick={() => props.onAddRealEstate()}>
                            <i className="fal fa-save"></i>&nbsp;{props.agentsReciveDataById.id ? "Update":"Save"} </button>
					</div>
				</div>

    </>
)
                        
      }
export default AddRealEstate;