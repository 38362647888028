import React from 'react';
import { Link } from "react-router-dom";

const Listcrmsetup=(props)=>{
 // console.log("this list page ",props.agentsReciveData);
 return(

 
<>
<nav aria-label="breadcrumb">
<ol className="breadcrumb">
  <li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
  <li className="breadcrumb-item active" aria-current="page">CRM Set-up</li>
</ol>
</nav>


<div className="card">
				<div className="card-body">
					<div className="d-flex align-items-center justify-content-between mb-3">
						<h5 className="hed01">CRM Set-up</h5>
						{/* <input className="form-control serch_hed" type="searchText"
             value={props.searchText}
             onChange={(e) => {
               props.searchHandleChange(e);
             }}
              placeholder="Search.."/>
						<Link to='../admin/listcrmsetup/addcrmsetup' className="btn btn-info rounded"><i className="fal fa-plus"></i> Add</Link> */}
					</div>
				
					<table className="table table-striped">
						<thead>
							<tr>
								<th scope="col">#</th>
								<th scope="col">CRM Name</th>
								<th scope="col">CRM Type</th>
								<th scope="col">Action</th>
							</tr>
						</thead>
						<tbody>
                            
      {props.thirdPartyReciveData && props.thirdPartyReciveData.length > 0 ? (
                  props.thirdPartyReciveData.map((item,index) => (
    <tr key ={index}>
      <th scope="row">{index + 1}</th>
      <td>{item.crmName}</td>
       <td>{item.crmType}</td>
       
       <td> <Link to={`/admin/listcrmsetup/addcrmsetup/edit/${item.id}`} title="Edit" className="btn btn-sm btn-outline-dark">
                         <i className="fal fa-pencil" onClick={()=>props.onEditClick(item.id)} ></i></Link>
             {/* <a href='#'
              title="Remove" 
              onClick={() =>props.openDeleteModal(item.id,1)}
              data-toggle="modal"
              data-target="#detele-popup"
             
             ><i className="far fa-trash"></i></a> */}
             </td></tr>
    ))
     ) : (
      <tr>
        <td  colSpan={5} >
          <div className="no-data text-center">
            {/* <img src="/assets/images/empty-box.svg" /> */}
            <h5>No Data Found</h5>
          </div>
        </td>
      </tr>
    )}




							
						</tbody>
					</table>
					
				</div>
			</div>
                        
           
              
 </>
    );
                        
      }
export default Listcrmsetup;