import React from 'react';
import { Link } from 'react-router-dom';
import "react-datetime/css/react-datetime.css";

const Addcrmsetup = (props) => {
	const {	
		clientid,
		name,
		crmtype,
		apiendpoint,
		apitoken,environment,otherinformation,status
	} = props.agentState
	//console.log("this is log",);
	return (
		<>

			<nav aria-label="breadcrumb">
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
					<li className="breadcrumb-item active" aria-current="page">CRM Set-up</li>
				</ol>
			</nav>
			<div className="card">
				<div className="card-body">
					<div className="d-flex align-items-center justify-content-between mb-1">
						<h5 className="hed01">CRM Set-up</h5>

					</div>
					<div className="row el_gr">
					<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>CRM Type *</label>
								
                                     <select className="form-control"
									name="crmtype" value={crmtype}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
									disabled={props.reciveceId?true:false}
								>
									<option value="0">--Select--</option>
									<option value="lightspeed">Light Speed</option>
									<option value="cscard">Cs-Cart</option>
									
								</select>
								<div className="errorMsg">{props.errors["crmtype"]}</div>
							</div>
						</div>
						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Client *</label>								
								<select className="form-control"
									name="clientid" value={clientid}
									onChange={(e) => props.onCrmFieldHnadleChange(e)}
									disabled={props.reciveceId?true:false}
								>
									{/* <option value="0">--Select--</option> */}
									{props.clientDropDawn && props.clientDropDawn.length > 0 && (
										props.clientDropDawn.map((item, index) => (
											<option key={index} value={item.id}>{item.client_name}</option>
										)))}
								</select>
								<div className="errorMsg">{props.errors["clientid"]}</div>
							</div>
						</div>

						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Environment*</label>
								
                                     <select className="form-control"
									name="environment" value={environment}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
									disabled={props.reciveceId?true:false}
								>
									<option value="0">--Select--</option>
									<option value="test">Test</option>
									<option value="live">Live</option>
									
								</select>
								<div className="errorMsg">{props.errors["environment"]}</div>
							</div>
						</div>

					<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Name *</label>
								<input className="form-control" type="text"
									name="name" value={name}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								/>
								<div className="errorMsg">{props.errors["name"]}</div>
							</div>
						</div>
						
						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>API EndPoint *</label>
								<input className="form-control" type="text"
									name="apiendpoint" value={apiendpoint}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								/>
								<div className="errorMsg">{props.errors["apiendpoint"]}</div>
							</div>
						</div>

						{/* <div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>API Key *</label>
								<input className="form-control" type="text"
									name="apikeys" value={apikeys}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								/>
								<div className="errorMsg">{props.errors["apikeys"]}</div>
							</div>
						</div> */}
						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>API Token *</label>
								<input className="form-control" type="text"
									name="apitoken" value={apitoken}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								/>
								<div className="errorMsg">{props.errors["apitoken"]}</div>
							</div>
						</div>
						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Other Information</label>
								<input className="form-control" type="text"
									name="otherinformation" value={otherinformation}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								/>
								
							</div>
						</div>

						
						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Status*</label>
								
                                     <select className="form-control"
									name="status" value={status}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								>
									{/* <option value="0">--Select--</option> */}
									<option value="1">Active</option>
									<option value="0">Deactive</option>
									
								</select>
								<div className="errorMsg">{props.errors["environment"]}</div>
							</div>
						</div>

						{/* <div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Account Id *</label>
								<input className="form-control" type="text"
									name="accountid" value={accountid}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								/>
								<div className="errorMsg">{props.errors["accountid"]}</div>
							</div>
						</div> */}

						{/* <div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Status</label>
								<select className="form-control"
									name="status" 
									value={status}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								>
									<option value="1" key="Active">Active</option>
									<option value="0" key="Deactive">Deactive</option>
								</select>
								{/* <div className="errorMsg">{props.errors["agentid"]}</div>
							</div>
						</div> */}
						{/* <div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Is schedule</label>
								<select className="form-control" type="text"
									name="is_schedule" value={is_schedule}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								>
									<option value="1" key="Yes">Yes</option>
									<option value="0" key="No">No</option>
								</select>
								<div className="errorMsg">{props.errors["is_schedule"]}</div>
							</div>
						</div> */}
						 {/* {is_schedule === "1" && (<>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Schedule start time *</label>
									<div className="date-time">
									<Datetime
										input={true}
										dateFormat={false}
										viewMode="time"
										timeFormat="HH:mm"
										//name="schedule_start_time"
										//value={moment(schedule_start_time).format(" h:mm") }
										value={schedule_start_time}
										onChange={(e) => props.onDataInputFieldHnadleChangeshedule(e)}
										timeConstraints={{
										hours: { min: 0, max: 23 },
										minutes: { min: 0, max: 59 }
										
										}}
									/>
									</div>
								 <input className="form-control" type="time" 
                                    name="schedule_start_time" value={schedule_start_time}
									pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    /> 
									<div className="errorMsg">{props.errors["schedule_start_time"]}</div>
								</div>
							</div>

							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Schedule Interval *</label>
									<input className="form-control" type="text"
										name="schedule_interval" value={schedule_interval}
										onChange={(e) => props.onDataInputFieldHnadleChange(e)}
									/>
									<div className="errorMsg">{props.errors["schedule_interval"]}</div>
								</div>
							</div>

						</>)} */}
					</div>
				</div>
			</div>
			<div className="down_pan">
				<div className="text-right">
					<Link to="/admin/listcrmsetup"><button className="btn_cf dim"><i className="fa fa-times" aria-hidden="true">&nbsp;</i> Cancel</button></Link> &nbsp;
					<button className="btn_cf scss" onClick={() => props.onAddRealEstate()}>
						<i className="fal fa-save"></i>&nbsp;{props.thirdPartyById.id ? "Update" : "Save"} </button>
				</div>
			</div>

		</>
	)

}
export default Addcrmsetup;