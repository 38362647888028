import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AddRealEstate from "./AddRealEstate"
import ListRealEstate from "./ListRealEstate"
import {AddRealAgentData, FetchRealAgentsList, FetchRealAgentById, UpdateRealAgentData,DeleteAgents} from "../../../../actions/RealEstateAction"
//import { propTypes } from "react-bootstrap/esm/Image";
import validateEstateAgentsForm from "../../../validation_rules/RealEstateFormRule";
//import Pagination from "../../../common/Pagination";
import PopupModal from "../../../common/PopupModal";
import Loading from "../../../common/Loading";

class ManageRealEstate extends Component {
  // constructor() {
  //   super();

  // }
initState ={
  agentname:"",
  address:"",
  contactnumber:"",
  emailid:"",
  address2:"",
  state:"",
  zipcode:"",
  status:1
}
 
state={
  agentState:this.initState,
  mode:"List",
  errors: {},
  pageNumber:1,
  pageSize:10,
  totalElements: 20,
  searchText: "",
  popupModalType: 0,
  popupTitle: "",
  deleteId:"",
  totallPage:""
}

onDataInputFieldHnadleChange=(e)=>{
  const { errors } = this.state;
    delete errors[e.target.name];
  this.setState({
    ...this.state,
    agentState: {
      ...this.state.agentState,
      [e.target.name]: e.target.value

    }
  })
}
onAddClick = ()=>{
  //this.props.history.push('../admin/addrealestate')
 this.setState({
  ...this.state,
  mode:"Add"
 },()=>{console.log("this.yutuui");})
}
onAddRealEstate = ()=>{
  const { agentname,
  address,
  contactnumber,
  emailid,
  address2,
  state,
  zipcode, } = this.state.agentState;
  let errors = validateEstateAgentsForm({ agentname,
    address,
    contactnumber,
    emailid,
    address2,
    state,
    zipcode, });
 
  if (Object.entries(errors).length > 0) {
      this.setState({
          ...this.state,
          errors: errors
      })
  } else{
    const params = {
      data: this.state.agentState
    }
   const upDateParams={
    data:this.state.agentState,
    id:this.props.agentsReciveDataById.id
   }
    if(this.props.agentsReciveDataById.id){
      this.props.UpdateRealAgentData(upDateParams)
      this.props.history.push("/admin/listrealestate")
    }else{
      this.props.AddRealAgentData(params);
      this.props.history.push("../admin/listrealestate")
    }
    
  } 
}

searchHandleChange = (e) => {
  this.setState({ ...this.state, searchText: e.target.value }
    ,()=>{console.log("this is search klog....",this.state.searchText);});
};
onSearchFormdata = async(pageNumber, pageSize) => {
  this.setState({
    ...this.state,
    pageNumber: pageNumber,
    pageSize: pageSize,
    //goToPageNumber: pageNumber,
   
  });
  const params = {
    page: pageNumber,
    size: pageSize,
    searchText: this.state.searchText,
   
  };
  // const cbLoading = () => {
  //   this.setState({
  //     isLoading: "DONE",
  //   });
  // };
  await this.props.FetchRealAgentsList(params);
  const {count,size}=this.props.paginationData;
 let totalPage=Math.ceil(+count/+size)
  this.setState({
    totallPage: totalPage,
  });
};




onEditClick =async (reciveId)=>{
  //console.log("consle.....");
  const params ={
    data:reciveId
  }
 await this.props.FetchRealAgentById(params)
 this.setState({
  ...this.state,
  agentState:this.props.agentsReciveDataById
 })
}
clearSelection = () => {
  this.setState({
    popupModalType: 0,
    popupTitle: "",
  });
};

openDeleteModal = (id, type) => {
 
  this.setState({
    deleteId: id,
    popupModalType: type,
    popupTitle:
    type === 1
        ? "you want to delete this!"
        : type === 2
        ? "you want to deactivate this!"
        : "you want to delete this!",
  });
};
onDelete = () => {
  const params = {
    data: {
      sp_id: this.state.deleteId
    }
}
  if (this.state.popupModalType > 0 && this.state.popupModalType === 1) {
    this.props.DeleteAgents(params,{});
    this.clearSelection();
  } else if (
    this.state.popupModalType > 0 &&
    this.state.popupModalType === 2
  ) {
    this.props.StatusChange(params);
    this.clearSelection();
  }else {
      this.props.DeleteAgents(params,{});
      this.clearSelection();

  }
};
onPopupCancel = (e) => {
  this.setState({
    ...this.state,
    popupModalType: 0,
    popupTitle: "",
  });
};

componentDidMount(){
 // let id=this.props.match.params?.id
 //this.onSearchFormdata(this.state.pageNumber, this.state.pageSize)
//  const params = {
//   data:id
// }
// this.props.FetchRealAgentById(params)

}

getDataOnEditMode = ()=>{
  this.setState({
    ...this.state,
    agentState:{
      ...this.state.agentState,
  agentname:this.props.agentsReciveDataById?.agentName,
  address:this.props.agentsReciveDataById?.agentAddress,
  contactnumber:this.props.agentsReciveDataById?.agentContact,
  emailid:this.props.agentsReciveDataById?.agentEmailId,
  address2:this.props.agentsReciveDataById?.agentAddress2,
  state:this.props.agentsReciveDataById?.agentState,
  zipcode:this.props.agentsReciveDataById?.agentZipcode,
  status:this.props.agentsReciveDataById?.status

    }
  })
}
componentDidUpdate(prevProps, prevState){
  if (this.props.agentsReciveDataById.id !== prevProps.agentsReciveDataById.id) {

    this.getDataOnEditMode();
  }
  if (this.state.searchText !== prevState.searchText) {
    if (this.state.searchText.length > 2) {
      this.onSearchFormdata(1, this.state.pageSize);
    } else if (this.state.searchText.length === 0) {
      this.onSearchFormdata(1, this.state.pageSize);
    }
  }
  
}
  render() {
 //console.log("this is reducer data",this.props.paginationData);
  //const {count,size}=this.props.paginationData;

// console.log("this is reducer data",(+this.props.paginationData.count/+this.props.paginationData.size))
    return (
      <React.Fragment>
        {this.props.location.pathname === "/admin/addrealestate"  &&
       <AddRealEstate
       agentState={this.state.agentState}
       onDataInputFieldHnadleChange={this.onDataInputFieldHnadleChange}
       onAddRealEstate={this.onAddRealEstate}
      // onEditClick={this.onEditClick}
      agentsReciveDataById={this.props.agentsReciveDataById}
      errors={this.state.errors}
       />
    }

{ this.props.location.pathname.includes("/admin/addrealestate/edit") &&
       <AddRealEstate
       agentState={this.state.agentState}
       onDataInputFieldHnadleChange={this.onDataInputFieldHnadleChange}
       onAddRealEstate={this.onAddRealEstate}
      // onEditClick={this.onEditClick}
      agentsReciveDataById={this.props.agentsReciveDataById}
      errors={this.state.errors}
       />
    }
        {this.props.location.pathname ==="/admin/listrealestate"&&( <>
          {/* {this.props.pending && <Loading/>} */}
       <ListRealEstate
       agentsReciveData={this.props.agentsReciveData}
       onAddClick={this.onAddClick}
       onEditClick={this.onEditClick}
       searchHandleChange={this.searchHandleChange}
       searchText={this.state.searchText}
       openDeleteModal={this.openDeleteModal}
       />
         <PopupModal
          popupId="detele-popup"
          onPopup={this.onDelete}
          type={this.state.popupModalType ? this.state.popupModalType : ""}
          popupText={this.state.popupTitle}
          onCancel={this.onPopupCancel}
        />
       {/* <Pagination
       // pageNumber={this.props.pageNumber}
       // pageSize={props.pageSize}
       // totalElements={totalElements}
       // totalPages={totalPages}
       updateView={this.onSearchFormdata}
       pageNumber={this.state.pageNumber}
       pageSize={size}
       totalElements={count}
       totalPages={this.state.totallPage}
       // value={props.goToPageNumber}
        handlePageChangeValue={this.props.handlePageChangeValue}
       // goToPage={props.goToPage}
     /> */}
     </>)
    }
      </React.Fragment>
    );
  }
}

//export default ManageSpecies;

const mapStateToProps = (state) => {
  let {
    AgentsReducer: {
      pending,
      error,
      agentsReciveData,
      agentsReciveDataById,
      paginationData
    }
  } = state;

  return {
    pending:pending,
      error:error,
      agentsReciveData:agentsReciveData,
      agentsReciveDataById:agentsReciveDataById,
      paginationData:paginationData
  };
};

ManageRealEstate.propTypes = {
  AddRealAgentData:PropTypes.func,
  FetchRealAgentsList:PropTypes.func,
  FetchRealAgentById :PropTypes.func,
  UpdateRealAgentData:PropTypes.func,
  DeleteAgents:PropTypes.func,
  //history: PropTypes.object,
  pending: PropTypes.bool,
  error: PropTypes.object,
};
export default connect(mapStateToProps, {
  AddRealAgentData,
  FetchRealAgentsList,
  FetchRealAgentById,
   UpdateRealAgentData,
   DeleteAgents
})(ManageRealEstate);