import React from 'react';
import { Link } from 'react-router-dom';
import "react-datetime/css/react-datetime.css";
const AddConnecters = (props) => {
	const {	
		clientid,	
		setupid,
		actionid,
		trigger,
	} = props.agentState
	const {triggeractionid ,triggersetupid}=trigger

	return (
		<>

			<nav aria-label="breadcrumb">
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
					<li className="breadcrumb-item active" aria-current="page">Connectors</li>
				</ol>
			</nav>
			<div className="card">
				<div className="card-body">
					<div className="d-flex align-items-center justify-content-between mb-1">
						<h5 className="hed01">Connectors</h5>
					</div>
					<div className="row el_gr">
					<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Client *</label>								
								<select className="form-control"
									name="clientid" value={clientid}
									onChange={(e) => props.onCrmFieldHnadleChange(e)}
								>
									{/* <option value="0">--Select--</option> */}
									{props.clientDropDawn && props.clientDropDawn.length > 0 && (
										props.clientDropDawn.map((item, index) => (
											<option key={index} value={item.id}>{item.client_name}</option>
										)))}
								</select>
								<div className="errorMsg">{props.errors["clientid"]}</div>
							</div>
						</div>
						<div className="card-body">
					<div className="d-flex align-items-center justify-content-between mb-1">
						<h7 className="hed01">Trigger</h7>
					</div>
					</div>
					<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Setup *</label>
								
                                     <select className="form-control"
									name="setupid" value={setupid}
									onChange={(e) => props.onHandleChangeSetup(e)}
								>
									<option value="0">--Select--</option>
									{props.setupdata && props.setupdata.length > 0 && (
										props.setupdata.map((item, index) => (
											<option key={index} value={item.id}>{item.crmName}</option>
										)))}
									
								</select>
								<div className="errorMsg">{props.errors["setupid"]}</div>
							</div>
						</div>
					

						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Event*</label>
								
                                     <select className="form-control"
									name="actionid" value={actionid}
									onChange={(e) => props.onActionHandleChange(e)}
								>
									<option value="0">--Select--</option>
									{props.actionSetUp && props.actionSetUp.length > 0 && (
										props.actionSetUp.map((item, index) => (
											<option key={index} value={item.id}>{item.action_name}</option>
										)))}
									
								</select>
								<div className="errorMsg">{props.errors["actionid"]}</div>
							</div>
						</div>
						<div className="d-flex align-items-center justify-content-between mb-1">
						<h7 className="hed01">Action</h7>

					</div>
					<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Setup *</label>
								
                                     <select className="form-control"
									name="triggersetupid" value={triggersetupid}
									onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								>
									<option value="0">--Select--</option>
									{props.triggerResponse&& Object.keys(props.triggerResponse).length > 0 && (<option value={props.triggerResponse.id}>{props.triggerResponse.crmName}</option>)}							
								</select>
								<div className="errorMsg">{props.errors["triggersetupid"]}</div>
							</div>
						</div>
						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Event*</label>								
								<select className="form-control"
									name="triggeractionid" value={triggeractionid}
									onChange={(e) => props.onTriggerActionHnadleChange(e)}
								>
									<option value="0">--Select--</option>
									{props.triggerResponse.crmAction && props.triggerResponse.crmAction.length > 0 && (
										props.triggerResponse.crmAction.map((item, index) => (
											<option key={index} value={item.id}>{item.action_name}</option>
										)))}
								</select>
								<div className="errorMsg">{props.errors["triggeractionid"]}</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div className="down_pan">
				<div className="text-right">
					<Link to="/admin/listconnecters"><button className="btn_cf dim"><i className="fa fa-times" aria-hidden="true">&nbsp;</i> Cancel</button></Link> &nbsp;
					<button className="btn_cf scss" onClick={() => props.onAddRealEstate()}>
						<i className="fal fa-save"></i>&nbsp;{props.thirdPartyById.id ? "Update" : "Save"} </button>
				</div>
			</div>

		</>
	)

}
export default AddConnecters;