import React, { Component} from 'react';
import { Link } from 'react-router-dom';

const AddEmailSetUp=(props)=>{
//   const{
//     agentid,
//       crmname,
//       environment,
//        baseurl,
//       authtokendpoint,
//       clientid,
//       clientsecretkey,
//       otherinformation,
// 	  updateStatusTime
//   }=props.agentState
  return (
    <>
       
       <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
						<li className="breadcrumb-item active" aria-current="page">Email Set-Up</li>
					</ol>
				</nav>
				<div className="card">
					<div className="card-body">
						<div className="d-flex align-items-center justify-content-between mb-1">
							<h5 className="hed01">Email Set-Up</h5>
							
						</div>	
						<div className="row el_gr">
						
						
						
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Enter Email *</label>	
									<input className="form-control" type="text" 
                                   // name="baseurl" value={baseurl}
                                    //onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    />
									{/* <div className="errorMsg">{props.errors["baseurl"]}</div> */}
								</div>
							</div>
							
						
							
                          
						</div>
					</div>
				</div>
				<div className="down_pan">
					<div className="text-right">
						{/* <Link to="/admin/listinternalcrm">
                            <button className="btn_cf dim">
                                <i className="fa fa-times" aria-hidden="true">&nbsp;</i> Cancel</button></Link> &nbsp; */}
						<button className="btn_cf scss" 
                        //</div> onClick={() => props.onAddRealEstate()}
                        >
                            <i className="fal fa-save"></i>&nbsp;
                            {/* {props.internalById.id ? "Update":"Save"}  */}
                            Save
                            </button>
					</div>
				</div>

    </>
)
                        
      }
export default AddEmailSetUp;