import axios from "axios";
import { getBasicAuthToken } from "../Utility/Utility";

const getLoginHeader = () => {
    const header = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Channel-Type": "WEB",
            // Authorization: getBasicAuthToken("", "")
        },
    };
    return header;
}
/**
 * Use to non secure method
 * @returns 
 */
const getWithoutToken = () => {
    const header = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Channel-Type": "WEB"
        },
    };
    return header;
}

/**
 * This method is use to return header when call normal api
 * @returns 
 */
const getWithToken = () => {
    const token = localStorage.getItem("token");
    const header = {
        headers: {
           "Content-Type": "application/json",
            "Channel-Type": "WEB",
           // "x-access-token": token ? `${token}` : ""
            Authorization: token ? `Bearer ${token}` : "",
        },
    };
    return header;
}
// console.log("log token", getWithToken());
/**
 * This method is use to return header values when file upload
 * @returns 
 */
const uploadFileHeader = () => {
    const token = localStorage.getItem("token");
    const header = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Channel-Type": "WEB",
            "x-access-token": token ? `${token}` : ""
        },
    };
    return header;
}

export const postRequestLogin = function (url, params) {
    const header = getLoginHeader();
    return axios.post(`${url}`, params["login_params"], header);
}

export const getRequest = (url, params) => {
    const header = getWithToken();
    return axios.get(`${url}`, header);
}

export const postRequest = function (url, params) {
    const header = getWithToken();
    return axios.post(`${url}`, params["data"], header);
}

export const postRequestToGetData = (url, params) => {
    const header = getWithToken();
    return axios.post(`${url}`, params["specie_id"], header);
}

export const postNonAuthRequest = (url, params) => {
    const header = getWithoutToken();

    return axios.post(`${url}`, params["data"], header);
}

export const postUploadRequest = (url, params) => {
    const header = uploadFileHeader();
    return axios.post(`${url}`, params["data"], header);
}

export const putRequest = (url, params) => {
    const header = getWithToken();
    return axios.put(`${url}`, params["data"], header);
}
export const postRequest2 = function (url, data) {
    const header = getWithToken();
    return axios.post(`${url}`, data, header);
}

export const deleteRequest = (url, params) => {
    const header = getWithToken();
    const token = localStorage.getItem("token");
   // return axios.delete(`${url}`, header, params["data"]);
  return axios.delete(`${url}`, {
    headers: {
        "x-access-token": token ? `${token}` : ""
    },
    data:params["data"]
  });
}