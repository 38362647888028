import React from 'react';
import { Link } from 'react-router-dom';

const Addcrmaction=(props)=>{
  const{
	setupid,
    name,
	actionurl
  
  }=props.agentState
  return (
    <>
       
       <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
						<li className="breadcrumb-item active" aria-current="page">Crm Action</li>
					</ol>
				</nav>
				<div className="card">
					<div className="card-body">
						<div className="d-flex align-items-center justify-content-between mb-1">
							<h5 className="hed01">Crm Action</h5>
							
						</div>	
						<div className="row el_gr">
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Set-up*</label>	
									<select className="form-control"
                                    name="setupid" value={setupid}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    >
										<option value=" ">--Select--</option>
										{props.setupdropdawn && props.setupdropdawn.length > 0 && (
                                        props.setupdropdawn.map((item,index) => (
										<option key={index} value={item.id}>{item.crmName}</option>
				                         )))}
									</select>
									<div className="errorMsg">{props.errors["setupid"]}</div>
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Event Name</label>	
									<input className="form-control" type="text" 
                                    name="name" value={name}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    />
									<div className="errorMsg">{props.errors["name"]}</div>
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Event URL</label>	
									<input className="form-control" type="text" 
                                    name="actionurl" value={actionurl}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    />
									
								</div>
							</div>
							{/* <div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Environment *</label>	
									<select className="form-control"
                                    name="environment" value={environment}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    >
										<option value="1">Live</option>
										{/* <option value="2">Test</option> 
									</select>
									<div className="errorMsg">{props.errors["environment"]}</div>
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Base URL *</label>	
									<input className="form-control" type="text" 
                                    name="baseurl" value={baseurl}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    />
									<div className="errorMsg">{props.errors["baseurl"]}</div>
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>OAuth 2.0 Token End Point *</label>	
									<input className="form-control" type="text"
                                    name="authtokendpoint" value={authtokendpoint}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)} 
                                    />
									<div className="errorMsg">{props.errors["authtokendpoint"]}</div>
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Client ID *</label>	
									<input className="form-control" type="text"
                                    name="clientid" value={clientid}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    />
									<div className="errorMsg">{props.errors["clientid"]}</div>
								</div>
							</div>

							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Client Secret *</label>	
									<input className="form-control" type="text"
                                    name="clientsecretkey" value={clientsecretkey}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    />
									<div className="errorMsg">{props.errors["clientsecretkey"]}</div>
								</div>
							</div>
							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Other Information </label>	
									<input className="form-control" type="text" 
                                    name="otherinformation" value={otherinformation}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    />
									{/* <div className="errorMsg">{props.errors["otherinformation"]}</div> 
								</div>
							</div>
                            <div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Status Update Miutes(Min.) *</label>	
									<input className="form-control" type="text" 
                                    name="updateStatusTime" value={updateStatusTime}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    />
									<div className="errorMsg">{props.errors["updateStatusTime"]}</div>
								</div>
							</div>

							<div className="col-sm-6 col-lg-4">
								<div className="el">
									<label>Status</label>	
									<select className="form-control"
                                    name="status" value={status}
                                    onChange={(e) => props.onDataInputFieldHnadleChange(e)}
                                    >
										<option value="1" key="Active">Active</option>
										<option value="0" key="Deactive">Deactive</option>
									</select>
									 {/* <div className="errorMsg">{props.errors["agentid"]}</div>  
								</div>
							</div> */}
						</div>
					</div>
				</div>
				<div className="down_pan">
					<div className="text-right">
						<Link to="/admin/listcrmaction"><button className="btn_cf dim"><i className="fa fa-times" aria-hidden="true">&nbsp;</i> Cancel</button></Link> &nbsp;
						<button className="btn_cf scss"  onClick={() => props.onAddRealEstate()}>
                            <i className="fal fa-save"></i>&nbsp;{props.internalById.id ? "Update":"Save"} </button>
					</div>
				</div>

    </>
)
                        
      }
export default Addcrmaction;