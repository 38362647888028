import { isEmail, isLength } from "validator";
import messages from "../common/messages";

export default function validateThiredPartyForm({
    clientid,
    name,
    crmtype,
    apiendpoint,
    apitoken,environment }) {
    let errors = {};
    if (!clientid) {
        errors.clientid = messages["clientid.required"];
    } 
 
      if (!name) {
        errors.name = messages["name.required"];
    }

    if (!environment) {
        errors.environment = messages["environment.required"];
    }

    if (!crmtype) {
        errors.crmtype = messages["crmtype.required"];
    }
    if (!apiendpoint) {
        errors.apiendpoint = messages["apiendpoint.required"];
    }
    if (!apitoken) {
        errors.apitoken = messages["apitoken.required"];
    }
   
    return errors; 
}