import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import {
  AddThirdPartyData, FetchThirdList,
  FetchThirdPartyById, UpdateThirdPartyData, FetchAgentsOption, FetchCrmExternalOption,
  DeleteThridParty,FetchClientOption
} from "../../../../actions/RealEstateAction"
import validateThiredPartyForm from "../../../validation_rules/ThirdPartyFormRule";
import Pagination from "../../../common/Pagination";
import PopupModal from "../../../common/PopupModal";
import Loading from "../../../common/Loading";
import Addcrmsetup from "./Addcrmsetup";
import Listcrmsetup from "./Listcrmsetup";

class Managecrmsetup extends Component {
  constructor() {
    super();
  }
  initState = {  
    clientid:"1",
    name:"",
    crmtype:"",
    apiendpoint:"",
    apitoken:"",
    environment:"",
    otherinformation:"",
    status:"1"

  }

  state = {
    agentState: this.initState,
    mode: "List",
    errors: {},
    pageNumber: 1,
    pageSize: 12,
    totalElements: 20,
    searchText: "",
    popupModalType: 0,
    popupTitle: "",
    deleteId: "",
    totallPage: "",
    getEndPoint:{}
  }

  onDataInputFieldHnadleChange = (e) => {
    const { errors } = this.state;
    delete errors[e.target.name];
    this.setState({
      ...this.state,
      agentState: {
        ...this.state.agentState,
        [e.target.name]: e.target.value

      }
    })
  }
  onDataInputFieldHnadleChangeshedule = (e) => {
    this.setState({
      ...this.state,
      agentState: {
        ...this.state.agentState,
        schedule_start_time: moment(e).format("HH:mm")
       // [e.target.name]: e.target.value

      }
    })
  }

  onCrmFieldHnadleChange = (e) => {

    const { errors } = this.state;
    delete errors[e.target.name];
    this.setState({
          ...this.state,
          agentState: {
            ...this.state.agentState,
            clientid: e.target.value,
          //  apiendpoints:""
          }
        })

    // if(e.target.value ==="0"){
    //   this.setState({
    //     ...this.state,
    //     agentState: {
    //       ...this.state.agentState,
    //       [e.target.name]: e.target.value,
    //     //  apiendpoints:""
    //     }
    //   })

    // }else{

  //  let reciveData =   this.props.crmExternalDropDawn &&  this.props.crmExternalDropDawn.find((item)=>item.id === +e.target.value);
  //     this.setState({
  //       ...this.state,
  //       agentState: {
  //         ...this.state.agentState,
  //         [e.target.name]: e.target.value,
  //         apiendpoints:reciveData.endPointUrl
  //       }
  //     }) 
  // }
  }

  onAddClick = () => {
    //this.props.history.push('../admin/addrealestate')
    this.setState({
      ...this.state,
      mode: "Add"
    })
  }
  onAddRealEstate = async() => {
    const { 
      clientid,
      name,
      crmtype,
      apiendpoint,
      apitoken,environment} = this.state.agentState;
    let errors = validateThiredPartyForm({
      clientid,
      name,
      crmtype,
      apiendpoint,
      apitoken,
      environment
    });

    if (Object.entries(errors).length > 0) {
      this.setState({
        ...this.state,
        errors: errors
      })
    } else {
      const params = {
        data: this.state.agentState
      }
      const upDateParams = {
        data: this.state.agentState,
        id: this.props.thirdPartyById.id
      }
      if (this.props.thirdPartyById.id) {
       // toast.success("Updated successfully!");
        await this.props.UpdateThirdPartyData(upDateParams)
        setTimeout(()=>{
          this.props.history.push("/admin/listcrmsetup")
          
        },3000)
      } else {
       // toast.success("Added successfully!");
        await this.props.AddThirdPartyData(params);
         setTimeout(()=>{
          this.props.history.push("/admin/listcrmsetup")      
        },3000)

       
      }
    }
  }
  onEditClick = async (reciveId) => {
    //console.log("consle.....");
    const params = {
      data: reciveId
    }
    await this.props.FetchThirdPartyById(params)
  // console.log("this.props.thirdPartyById..",this.props.thirdPartyById.id);
    this.setState({
      ...this.state,
      agentState:{
        ...this.state.agentState,
        clientid:this.props.thirdPartyById.client_id,
        name:this.props.thirdPartyById.crmName,
        crmtype:this.props.thirdPartyById.crmType,
        apiendpoint:this.props.thirdPartyById.apiEndPoint,
        apitoken:this.props.thirdPartyById.apiToken
      }
    
    })
  }

  clearSelection = () => {
    this.setState({
      popupModalType: 0,
      popupTitle: "",
    });
  };

  openDeleteModal = (id, type) => {
    
    this.setState({
      deleteId: id,
      popupModalType: type,
      popupTitle:
        type === 1
          ? "you want to delete this!"
          : type === 2
            ? "you want to deactivate this!"
            : "you want to delete this!",
    });
  };
  onDelete = () => {
    const params = {
      data: {
        sp_id: this.state.deleteId
      }
    }
    if (this.state.popupModalType > 0 && this.state.popupModalType === 1) {
      this.props.DeleteThridParty(params, {});
      this.clearSelection();
    } else if (
      this.state.popupModalType > 0 &&
      this.state.popupModalType === 2
    ) {
      this.props.StatusChange(params);
      this.clearSelection();
    } else {
      this.props.DeleteThridParty(params, {});
      this.clearSelection();

    }
  };
  onPopupCancel = (e) => {
    this.setState({
      ...this.state,
      popupModalType: 0,
      popupTitle: "",
    });
  };



  onSearchFormdata = async (pageNumber, pageSize) => {
    this.setState({
      ...this.state,
      pageNumber: pageNumber,
      pageSize: pageSize,
      //goToPageNumber: pageNumber,

    });
    const params = {
      page: pageNumber,
      size: pageSize,
      searchText: this.state.searchText,

    };
    // const cbLoading = () => {
    //   this.setState({
    //     isLoading: "DONE",
    //   });
    // };
    await this.props.FetchThirdList(params);
    const { count, size } = this.props.paginationData1;
    let totalPage = Math.ceil(count / 12)
    this.setState({
      totallPage: totalPage,
    });
  };


  searchHandleChange = (e) => {
    this.setState({ ...this.state, searchText: e.target.value }
      , () => { console.log("this is search klog....", this.state.searchText); });
  };

  async componentDidMount() {
    await this.props.FetchClientOption();
    let id = this.props.match.params?.id
    this.findEndPoint()
    this.onSearchFormdata(this.state.pageNumber, this.state.pageSize)
    const params = {
      data: id
    }
  
    await this.props.FetchThirdPartyById(params)
  }
  findEndPoint =()=>{
    let recivedata= this.props.crmExternalDropDawn &&  this.props.crmExternalDropDawn.find(item=> item.endPointUrl);
    this.setState({
      ...this.state,
        getEndPoint:recivedata,
    })
  }
  getDataOnEditMode = async () => {
    const params = {
      data: this.props.thirdPartyById?.id
    }
    await this.props.FetchThirdPartyById(params)
   
    this.setState({
      ...this.state,
      agentState:{
        ...this.state.agentState,
        clientid:this.props.thirdPartyById.client_id,
        name:this.props.thirdPartyById.crmName,
        crmtype:this.props.thirdPartyById.crmType,
        apiendpoint:this.props.thirdPartyById.apiEndPoint,
        apitoken:this.props.thirdPartyById.apiToken,
        environment:this.props.thirdPartyById.environment,
        otherinformation:this.props.thirdPartyById.other_information



      }
    })
  }
  componentDidUpdate(prevProps, prevState) {
    
    if (this.props.thirdPartyById.id !== prevProps.thirdPartyById.id) {
     // console.log("log on didmount",this.props.thirdPartyById.id);
      this.getDataOnEditMode();
    }
    if (this.state.searchText !== prevState.searchText) {
      if (this.state.searchText.length > 2) {
        this.onSearchFormdata(1, this.state.pageSize);
      } else if (this.state.searchText.length === 0) {
        this.onSearchFormdata(1, this.state.pageSize);
      }
    }

  }
  render() {
    const { count } = this.props?.paginationData1;
    let id = this.props.match.params?.id
    return (
      <React.Fragment>
        {this.props.location.pathname === "/admin/listcrmsetup/addcrmsetup" &&
          <Addcrmsetup
            agentState={this.state.agentState}
            onDataInputFieldHnadleChange={this.onDataInputFieldHnadleChange}
            onDataInputFieldHnadleChangeshedule={this.onDataInputFieldHnadleChangeshedule}
            onCrmFieldHnadleChange={this.onCrmFieldHnadleChange}
            onAddRealEstate={this.onAddRealEstate}
            errors={this.state.errors}
            thirdPartyById={this.props.thirdPartyById}
            agentsDropDawn={this.props.agentsDropDawn}
            clientDropDawn={this.props.clientDropDawn}
            crmExternalDropDawn={this.props.crmExternalDropDawn}
            reciveceId={id}
          />
        }

        {this.props.location.pathname.includes("/admin/listcrmsetup/addcrmsetup/edit") &&
          <Addcrmsetup
            agentState={this.state.agentState}
            onDataInputFieldHnadleChange={this.onDataInputFieldHnadleChange}
            onDataInputFieldHnadleChangeshedule={this.onDataInputFieldHnadleChangeshedule}
            onCrmFieldHnadleChange={this.onCrmFieldHnadleChange}
            onAddRealEstate={this.onAddRealEstate}
            errors={this.state.errors}
            thirdPartyById={this.props.thirdPartyById}
            agentsDropDawn={this.props.agentsDropDawn}
            clientDropDawn={this.props.clientDropDawn}
            crmExternalDropDawn={this.props.crmExternalDropDawn}
            reciveceId={id}
          />
        }
        {this.props.location.pathname === "/admin/listcrmsetup" &&
          (<>
                    {this.props.pending && <Loading/>}

            <Listcrmsetup
              thirdPartyReciveData={this.props.thirdPartyReciveData}
              onAddClick={this.onAddClick}
              onEditClick={this.onEditClick}
              searchHandleChange={this.searchHandleChange}
              searchText={this.state.searchText}
              openDeleteModal={this.openDeleteModal}
            />
            <PopupModal
              popupId="detele-popup"
              onPopup={this.onDelete}
              type={this.state.popupModalType ? this.state.popupModalType : ""}
              popupText={this.state.popupTitle}
              onCancel={this.onPopupCancel}
            />
            <Pagination
              // pageNumber={this.props.pageNumber}
              // pageSize={props.pageSize}
              // totalElements={totalElements}
              // totalPages={totalPages}
              updateView={this.onSearchFormdata}
              pageNumber={this.state.pageNumber}
              pageSize={12}
              totalElements={count}
              totalPages={this.state.totallPage}
              // value={props.goToPageNumber}
              handlePageChangeValue={this.props.handlePageChangeValue}
            // goToPage={props.goToPage}
            />
          </>)

        }
      </React.Fragment>
    );
  }
}

//export default ManageSpecies;

const mapStateToProps = (state) => {
  let {
    AgentsReducer: {
      pending,
      error,
      thirdPartyReciveData,
      thirdPartyById,
      agentsDropDawn,
      crmExternalDropDawn,
      paginationData1,
      clientDropDawn
    }
  } = state;

  return {
    pending: pending,
    error: error,
    thirdPartyReciveData: thirdPartyReciveData,
    thirdPartyById: thirdPartyById,
    agentsDropDawn: agentsDropDawn,
    crmExternalDropDawn: crmExternalDropDawn,
    paginationData1: paginationData1,
    clientDropDawn:clientDropDawn
  };
};

Managecrmsetup.propTypes = {
  AddThirdPartyData: PropTypes.func,
  FetchThirdList: PropTypes.func,
  FetchAgentsOption: PropTypes.func,
  FetchCrmExternalOption: PropTypes.func,
  FetchRealAgentById: PropTypes.func,
  FetchThirdPartyById: PropTypes.func,
  UpdateThirdPartyData: PropTypes.func,
  UpdateRealAgentData: PropTypes.func,
  DeleteThridParty: PropTypes.func,
  FetchClientOption:PropTypes.func,
  //history: PropTypes.object,
  pending: PropTypes.bool,
  error: PropTypes.object,
};
export default connect(mapStateToProps, {
  AddThirdPartyData,
  FetchThirdList,
  FetchAgentsOption,
  FetchCrmExternalOption,
  FetchThirdPartyById,
  UpdateThirdPartyData,
  DeleteThridParty,
  FetchClientOption
})(Managecrmsetup);