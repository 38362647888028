import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../module/admin/Dashboard";
import NoMatch from "./NoMatch";
import ListRealEstate from "../module/admin/RealEstateEgents/ManageRealEstate";
import AddRealEstate from "../module/admin/RealEstateEgents/ManageRealEstate";
import Listcrmsetup from "../module/admin/CRMsetup/Managecrmsetup";
import Addcrmsetup from "../module/admin/CRMsetup/Managecrmsetup";
// import AddInternal from "../module/admin/InternelCrm/ManageInternalCrm";
// import ListInternalCrm from "../module/admin/InternelCrm/ManageInternalCrm";
import ListConnectrs from "../module/admin/Connecters/ManageConnecters";
import AddConnecters from "../module/admin/Connecters/ManageConnecters";
import AddEmailSetUp from "../module/admin/EmailSetup/ManageEmailSetup";
import ListGlobleLog from "../module/admin/GlobleConnectorLog/ManageGlobleConnector";
import LogHistory from "../module/admin/GlobleConnectorLog/ManageGlobleConnector";
import Addcrmaction from "../module/admin/CrmAction/ManageCrmAction";
import ListCrmAction from "../module/admin/CrmAction/ManageCrmAction";
import ViewConnector from "../module/admin/Connecters/ManageConnecters";
const PrivateRouteArr = [
  {
    path: `/admin`,
    exact: true,
    component: ListRealEstate,
  },
  {
    path: `/admin/listrealestate`,
    exact: true,
    component: ListRealEstate,
  },
  {
    path: `/admin/`,
    exact: true,
    component: ListRealEstate,
  },

  {
    path: `/admin/addrealestate`,
    exact: true,
    component: AddRealEstate,
  },

  {
    path: `/admin/addrealestate/:mode/:id`,
    exact: true,
    component: AddRealEstate,
  },
  {
    path: `/admin/listcrmsetup`,
    exact: true,
    component: Listcrmsetup,
  },

  {
    path: `/admin/listcrmsetup/addcrmsetup`,
    exact: true,
    component: Addcrmsetup,
  },

  {
    path: `/admin/listcrmsetup/addcrmsetup/:mode/:id`,
    exact: true,
    component: Addcrmsetup,
  },



  {
    path: `/admin/listcrmaction`,
    exact: true,
    component: ListCrmAction,
  },

  {
    path: `/admin/listcrmaction/addcrmaction`,
    exact: true,
    component: Addcrmaction,
  },

  {
    path: `/admin/listcrmaction/addcrmaction/:mode/:id`,
    exact: true,
    component: Addcrmaction,
  },

  {
    path: `/admin/listconnecters`,
    exact: true,
    component: ListConnectrs,
  },
  {
    path: `/admin/listconnecters/addconnecters`,
    exact: true,
    component: AddConnecters,
  },

  {
    path: `/admin/listconnecters/:mode/:id`,
    exact: true,
    component: ViewConnector,
  },
  {
    path: `/admin/addemailsetup`,
    exact: true,
    component: AddEmailSetUp,
  },

  {
    path: `/admin/listGlobleLog`,
    exact: true,
    component: ListGlobleLog,
  },
  {
    path: `/admin/logDetail/:mode/:id`,
    exact: true,
    component: LogHistory,
  },
];

class PrivateNav extends Component {
  render() {
    return (
      <Switch>
        {PrivateRouteArr.map(
          ({ path, exact, component: Component, ...rest }) => {
            return (
              <PrivateRoute
                key={path}
                path={path}
                exact={exact}
                component={Component}
                props={this.props}
                {...rest}
              />
            );
          }
        )}
        <Route render={(props) => <NoMatch {...this.props} />} />
      </Switch>
    );
  }
}
export default PrivateNav;