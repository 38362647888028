import React from 'react';
import { Link } from 'react-router-dom';
import "react-datetime/css/react-datetime.css";
const ViewConnector = (props) => {
	
	return (
		<>

			<nav aria-label="breadcrumb">
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
					<li className="breadcrumb-item active" aria-current="page">Connectors</li>
				</ol>
			</nav>
			<div className="card">
				<div className="card-body">
					<div className="d-flex align-items-center justify-content-between mb-1">
						<h5 className="hed01">Connectors</h5>
					</div>
					<div className="row el_gr">
					<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Client *</label>								
								<input className="form-control"
									 value={props.connectorview?.client?.client_name}
                                     disabled
									//onChange={(e) => props.onCrmFieldHnadleChange(e)}
								/>
									
								
							</div>
						</div>
						<div className="card-body">
					<div className="d-flex align-items-center justify-content-between mb-1">
						<h7 className="hed01">Trigger</h7>
					</div>
					</div>
					<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Setup *</label>
								
                                     <input className="form-control"
									  value={props.connectorview?.crmSetup?.crmName}
                                      disabled
									//onChange={(e) => props.onHandleChangeSetup(e)}
								/>
								
							</div>
						</div>
					

						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Event*</label>
								
                                     <input className="form-control"
									 value={props.connectorview?.action?.action_name}
                                     disabled
									//onChange={(e) => props.onActionHandleChange(e)}
								/>
									
							</div>
						</div>
						<div className="d-flex align-items-center justify-content-between mb-1">
						<h7 className="hed01">Action</h7>

					</div>
					<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Setup *</label>
								
                                     <input className="form-control"
									value={props?.connectorview?.trigger?.[0]?.crmSetup?.crmName}                                    
                                    disabled
									//onChange={(e) => props.onDataInputFieldHnadleChange(e)}
								/>
									
							</div>
						</div>
						<div className="col-sm-6 col-lg-4">
							<div className="el">
								<label>Event*</label>								
								<input className="form-control"
									value={props.connectorview?.action?.action_name}									
                                    disabled
								/>
								
							</div>
						</div>

					</div>
				</div>
			</div>
			<div className="down_pan">
				<div className="text-right">
					<Link to="/admin/listconnecters"><button className="btn_cf dim"><i className="fa fa-times" aria-hidden="true">&nbsp;</i> Cancel</button></Link> &nbsp;
					
				</div>
			</div>

		</>
	)

}
export default ViewConnector;