import React from "react";
import { Switch, withRouter } from "react-router-dom";
import HomeLayout from "./HomeLayout";
import LoginLayout from "./LoginLayout";


const index = (props) => {
     let { pathname } = props.location;
    
    let header = <HomeLayout {...props} />
 if (pathname.includes("admin/login") === true) { 
        header = <LoginLayout {...props} />
    } else if(pathname==='/'){
        header = <LoginLayout {...props} />
    }
    return <Switch>{header}</Switch>
};
export default withRouter(index);
