import React, { Component } from "react";
 import { NavLink } from "react-router-dom";
// import { Accordion, Card, Button } from 'react-bootstrap';


class Sidebar extends Component {

    constructor() {
        super();
        this.state = {
            toggleSidebar: false
        }
    }

    state={
        realStateAgents:"",
        thirdPartyClass:"",
        internalClass:"",
        connecterClass:"",
        globleLogClass:"",
        emailSetUpClass:"",
    }
    toggleSidebar = () => {
        this.setState({
            toggleSidebar: !this.state.toggleSidebar
        }, () => {
            if (this.state.toggleSidebar) {
                document.getElementsByTagName('body')[0].classList.add('sidebar-toggled')
            } else {
                document.getElementsByTagName('body')[0].classList.remove('sidebar-toggled')
            }
        })
    }
componentDidMount(){
    if(this.props.location?.pathname ==="/admin/listrealestate" || 
    this.props.location?.pathname ==="/admin/addrealestate" || 
    this.props.location.pathname.includes("/admin/addinternalcrm/edit")){
        this.setState({
            ...this.state,
            realStateAgents:"nav-NavLink is-active"
        })

    }else{
        this.setState({
            ...this.state,
            realStateAgents:"nav-NavLink"
        })

    }

    if(this.props.location?.pathname ==="/admin/addcrmsetup" || 
    this.props.location?.pathname ==="/admin/listcrmsetup" || 
    this.props.location.pathname.includes("/admin/addcrmsetup/edit")){
        this.setState({
            ...this.state,
            thirdPartyClass:"nav-NavLink is-active"
        })

    }else{
        this.setState({
            ...this.state,
            thirdPartyClass:"nav-NavLink"
        })

    }
    if(this.props.location?.pathname ==="/admin/listcrmaction" || 
    this.props.location?.pathname ==="/admin/addcrmaction" || 
    this.props.location.pathname.includes("/admin/addcrmaction/edit")){
        this.setState({
            ...this.state,
            internalClass:"nav-NavLink is-active"
        })

    }else{
        this.setState({
            ...this.state,
            internalClass:"nav-NavLink"
        })

    }

    if(this.props.location?.pathname ==="/admin/addconnecters" || 
    this.props.location?.pathname ==="/admin/listconnecters" || 
    this.props.location.pathname.includes("/admin/addconnectors/edit")){
        this.setState({
            ...this.state,
            connecterClass:"nav-NavLink is-active"
        })

    }else{
        this.setState({
            ...this.state,
            connecterClass:"nav-NavLink"
        })

    }

    if(this.props.location?.pathname ==="/admin/listGlobleLog" ||
    this.props.location.pathname.includes("/admin/logDetail/view/")
    ){
        this.setState({
            ...this.state,
            globleLogClass:"nav-NavLink is-active"
        })

    }else{
        this.setState({
            ...this.state,
            globleLogClass:"nav-NavLink"
        })

    }


    if(this.props.location?.pathname ==="/admin/addemailsetup" ){
        this.setState({
            ...this.state,
            emailSetUpClass:"nav-NavLink is-active"
        })

    }else{
        this.setState({
            ...this.state,
            emailSetUpClass:"nav-NavLink"
        })

    }
}

    render() {
       
        return (
            <React.Fragment>
                
                <div className="menu-list">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <a className="d-xl-none d-lg-none" href="#">Dashboard</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav flex-column">
                            <li className="nav-divider">
                             
                            </li>
          
                        



                            <li className="nav-item ">
                            <NavLink  to="/admin/listrealestate" className="nav-link" activeClassName='is-active' > <i className="fad fa-file-user"></i><span> Client Management</span></NavLink>
                        </li>
                        
                        <li className="nav-item">
                            <NavLink  to="/admin/listcrmsetup" className="nav-link" activeClassName='is-active' >
                                 <i className="fal fa-user-cog"></i><span> CRM Set-up</span></NavLink>
                        </li>


                        <li className="nav-item ">
                            <NavLink to="/admin/listcrmaction"  className="nav-link" activeClassName='is-active' >
                                 <i className="fal fa-cogs"></i><span>CRM Action</span></NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink  to="/admin/listconnecters"  className="nav-link" activeClassName='is-active'>
                                 <i className="fal fa-globe-stand"></i><span> Connectors</span></NavLink>
                        </li>
                            {/* <li className="nav-item">
                                <a className="nav-NavLink active" href="/admin/listrealestate"><i className="fad fa-file-user"></i> <span>Real Estate Agent</span></a>
                            </li>
							<li className="nav-item">
                                <a className="nav-NavLink" href="/admin/listthirdparty"><i className="fal fa-user-cog"></i> <span>Third Party CRM</span></a>
                            </li> */}
							{/* <li className="nav-item">
                                <a className="nav-NavLink" href="/admin/listinternalcrm"><i className="fal fa-cogs"></i> <span>Internal CRM</span></a>
                            </li>
							<li className="nav-item">
                                <a className="nav-NavLink" href="/admin/listconnecters"><i className="far fa-NavLink"></i> <span>Connectors</span></a>
                            </li> */}
							{/* <li className="nav-item">
                                <a className={this.state.globleLogClass} href="/admin/listGlobleLog"><i className="fal fa-globe-stand"></i> <span>Global Connectors Log</span></a>
                            </li>
                            <li className="nav-item">
                                <a className={this.state.emailSetUpClass} href="/admin/addemailsetup"><i className="fal fa-envelope"></i> <span>Set-Up</span></a>
                            </li> */}
                        </ul>
                    </div>
                </nav>
            </div>
       
            </React.Fragment >
        )
    }
}

export default Sidebar;