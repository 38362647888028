export const GATEWAY_URL = "http://bullrageconnector.broadwayinfotech.net.au:5003/api/";
// export const GATEWAY_URL = "http://localhost:5000/api/";
export const LOGIN_URL = `${GATEWAY_URL}user/login`;
export const LOGOUT_URL = "";
export const AGENTS_ADD_URL =  `${GATEWAY_URL}user/addagent`;
export const AGENTS_LIST_URL =  `${GATEWAY_URL}user/agentlist?keyword=`;
export const AGENTS_UPDATE_URL =  `${GATEWAY_URL}user/agentupdate`;
export const AGENTS_UPDATE_GET_URL =  `${GATEWAY_URL}user/editagent`;
export const AGENTS_DELETE_URL =  `${GATEWAY_URL}user/agentdelete`;


///Species Api list Started addagent//// agentlist 
// third party crm addthirdparty 
export const AGENTS_DROP_DAWN =  `${GATEWAY_URL}user/agents`;
export const THIRD_ADD_URL =  `${GATEWAY_URL}user/addcrm`;
export const THIRD_LIST_URL =  `${GATEWAY_URL}user/crmlist?keyword=`;
export const THIRD_PARTY_UPDATE_URL =  `${GATEWAY_URL}user/updatecrm`;
export const THIRD_PARTY_UPDATE_GET_URL =  `${GATEWAY_URL}user/getcrmaction`;
export const THIRD_PARTY_DELETE_URL =  `${GATEWAY_URL}user/thirdpartydelete`;

//Internal list is started
export const INTERNAL_LIST_URL =  `${GATEWAY_URL}user/actionlist?keyword=`;
export const INTERNAL_ADD_URL =  `${GATEWAY_URL}user/addaction`;
export const INTERNAL_UPDATE_URL =  `${GATEWAY_URL}user/internalcrmupdate`;
export const INTERNAL_UPDATE_GET_URL =  `${GATEWAY_URL}user/editinternalcrm`;
export const INTERNAL_DELETE_URL =  `${GATEWAY_URL}user/internalcrmdelete`;

//CRM drop dawn

export const CRM_DROP_DAWN_EXTERNAL_URL =  `${GATEWAY_URL}user/crmdropdown/external`;
export const CRM_DROP_DAWN_INTERNAL_URL =  `${GATEWAY_URL}user/getsetupbyclient/1`;

//API Connector list is started
export const CONNECTOR_LIST_URL =  `${GATEWAY_URL}user/getallconnector?keyword=`;
export const CONNECTOR_ADD_URL =  `${GATEWAY_URL}user/addconnector`;
export const CONNECTOR_TRIGGER_URL =  `${GATEWAY_URL}user/gettrigger`;
export const CONNECTOR_UPDATE_URL =  `${GATEWAY_URL}user/apiconnectorupdate`;
export const CONNECTOR_UPDATE_GET_URL =  `${GATEWAY_URL}user/editapiconnector`;
export const CONNECTOR_GET_ALL_DROPDOWN_URL =  `${GATEWAY_URL}user/allconnectordrowdown/`;

export const CONNECTOR_STATUS_UPDATE_URL =  `${GATEWAY_URL}user/connectorstatusupdate/`;

//API History Log
export const HISTORY_LOG_API =  `${GATEWAY_URL}user/propertyprocessloglist?keyword=`;
export const HISTORY_LOG_DETAILS_API =  `${GATEWAY_URL}user/propertyprocesslogdetails/`;
export const CONNECTOR_DROP_DAWN_URL =  `${GATEWAY_URL}user/apiconnectorlist?keyword=`;
 //API CRM SET-UP
 export const CRM_SETUP_DROP_DAWN_URL =  `${GATEWAY_URL}user/getclientlist`;
export const CONNECTOR_VIEW_URL =`${GATEWAY_URL}user/getconnector/`
export const CONNECTOR_DELETE_URL =  `${GATEWAY_URL}user/deleteconnector`;
