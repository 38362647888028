import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
//import { toast } from "react-toastify";
import moment from 'moment'
import {
  AddConnecterData, FetchThirdList,FetchConnecterList,
  FetchThirdPartyById, UpdateThirdPartyData, FetchAgentsOption, FetchCrmExternalOption,
  DeleteConnecter,FetchClientOption, FetchCrmInternalOption,FetchActionBySetup,FindTriggerData,
  FetchConnectorView
} from "../../../../actions/RealEstateAction"
//import { propTypes } from "react-bootstrap/esm/Image";
import validateConnectorForm from "../../../validation_rules/ConnecterFormRule";
import Pagination from "../../../common/Pagination";
import PopupModal from "../../../common/PopupModal";
import Loading from "../../../common/Loading";
//import { Link } from "react-router-dom/cjs/react-router-dom.min";
import AddConnecters from "./AddConnecters";
import ListConnecters from "./ListConnecters";
import ViewConnector from "./ViewConnector";

class ManageConnecters extends Component {
  constructor() {
    super();
  }
  initState = {  
    clientid:"1",
    setupid:"",
    actionid:"",
    trigger: {
      triggersetupid: "",
      triggeractionid: ""
    }

  }

  state = {
    agentState: this.initState,
    mode: "List",
    errors: {},
    pageNumber: 1,
    pageSize: 12,
    totalElements: 20,
    searchText: "",
    popupModalType: 0,
    popupTitle: "",
    deleteId: "",
    totallPage: "",
    getEndPoint:{},
    actionSetUp:[],
    ricveTriggerData:[]
  }

  onDataInputFieldHnadleChange = (e) => {
    const { errors } = this.state;
    delete errors[e.target.name];
    this.setState({
      ...this.state,
      agentState: {
        ...this.state.agentState,
        trigger:{
          ...this.state.agentState.trigger,
        triggersetupid: e.target.value
        }
      }
    })
  }

  onTriggerActionHnadleChange = (e) => {
    const { errors } = this.state;
    delete errors[e.target.name];
    this.setState({
      ...this.state,
      agentState: {
        ...this.state.agentState,
        trigger:{
          ...this.state.agentState.trigger,
        triggeractionid: e.target.value
        }
      }
    })
  }


  onDataInputFieldHnadleChangeshedule = (e) => {
    this.setState({
      ...this.state,
      agentState: {
        ...this.state.agentState,
        schedule_start_time: moment(e).format("HH:mm")
       // [e.target.name]: e.target.value

      }
    })
  }

  onHandleChangeSetup = (e) => {
    const { errors } = this.state;
    delete errors[e.target.name];
    this.setState({
          ...this.state,
          agentState: {
            ...this.state.agentState,
            setupid: e.target.value,
         
          }
        },async()=>{
         await this.props.FetchActionBySetup(e.target.value)
          this.setState({
            ...this.state,
            actionSetUp:this.props.actionBySetupId?.crmAction
          })
        
         if(this.state.agentState.setupid !== "" && this.state.agentState.actionid !== ""){
         
          const params={
            setupid: e.target.value,
            actionname:this.state.agentState.actionid
          }
          await this.props.FindTriggerData(params)
          this.setState({
            ...this.state.agentState,
            ricveTriggerData:this.props.triggerResponse
          })
         
         }
            })
  }

  actionOption =(value)=>{
    this.props.FetchActionBySetup(value)
    this.setState({
      ...this.state,
      actionSetUp:this.props.actionBySetupId?.crmAction
    })
  }

 

  onActionHandleChange =async (e) => {
  
    const { errors } = this.state;
    delete errors[e.target.name];
    this.setState({
      ...this.state,
      agentState: {
        ...this.state.agentState,
        [e.target.name]: e.target.value
      }
    })
   const params={
      setupid:this.state.agentState.setupid,
      actionname:e.target.selectedOptions[0].text
    }
    await this.props.FindTriggerData(params)
    this.setState({
      ...this.state.agentState,
      ricveTriggerData:this.props.triggerResponse
    })
   
  }

  onCrmFieldHnadleChange = (e) => {

    const { errors } = this.state;
    delete errors[e.target.name];
    this.setState({
          ...this.state,
          agentState: {
            ...this.state.agentState,
            clientid: e.target.value,
          //  apiendpoints:""
          }
        })

   
  }

  onAddClick = () => {
   
    this.setState({
      ...this.state,
      mode: "Add"
    })
  }
  onAddRealEstate = async() => {
    const { 
      clientid,	
      setupid,
      actionid,
      trigger,
   } = this.state.agentState;
   const {triggersetupid, triggeractionid}=trigger
    let errors = validateConnectorForm({
      clientid,	
      setupid,
      actionid,
      triggersetupid,
      triggeractionid
    });

    if (Object.entries(errors).length > 0) {
      this.setState({
        ...this.state,
        errors: errors
      })
    } else {
      
      const params = {
        data: this.state.agentState
      }

     
      const modifyedData= {...params.data,trigger:{setupid:triggersetupid,actionid:triggeractionid}}
     // console.log("this is params...",modifyedData);
      const upDateParams = {
        data: this.state.agentState,
        id: this.props.thirdPartyById.id
      }
      if (this.props.thirdPartyById.id) {
        await this.props.UpdateThirdPartyData(upDateParams)
        setTimeout(()=>{
          this.props.history.push("/admin/listconnecters")
        },3000)
      } else {
        await this.props.AddConnecterData(modifyedData); 
      }
    }
  }
  onEditClick = async (reciveId) => {
    //console.log("consle.....");
    const params = {
      data: reciveId
    }
    await this.props.FetchThirdPartyById(params)
   await this.props.FetchConnectorView(params)
   
  }

  clearSelection = () => {
    this.setState({
      popupModalType: 0,
      popupTitle: "",
    });
  };

  openDeleteModal =async (id, type) => {
    
  console.log("this is log...",id);
 const params ={
  id:[id]
 }
  await this.props.DeleteConnecter(params);
  };
  onDelete = () => {
    const params = {
      data: {
        sp_id: this.state.deleteId
      }
    }
    if (this.state.popupModalType > 0 && this.state.popupModalType === 1) {
      this.props.DeleteConnecter(params, {});
      this.clearSelection();
    } else if (
      this.state.popupModalType > 0 &&
      this.state.popupModalType === 2
    ) {
      this.props.StatusChange(params);
      this.clearSelection();
    } else {
      this.props.DeleteConnecter(params, {});
      this.clearSelection();

    }
  };
  onPopupCancel = (e) => {
    this.setState({
      ...this.state,
      popupModalType: 0,
      popupTitle: "",
    });
  };



  onSearchFormdata = async (pageNumber, pageSize) => {
    this.setState({
      ...this.state,
      pageNumber: pageNumber,
      pageSize: pageSize,
      //goToPageNumber: pageNumber,

    });
    const params = {
      page: pageNumber,
      size: pageSize,
      searchText: this.state.searchText,

    };
  
    await this.props.FetchConnecterList(params);
    const { count } = this.props.paginationData2;
    let totalPage = Math.ceil(count / 12)
    this.setState({
      totallPage: totalPage,
    });
  };


  searchHandleChange = (e) => {
    this.setState({ ...this.state, searchText: e.target.value }
      , () => { console.log("this is search klog....", this.state.searchText); });
  };

  async componentDidMount() {
    await this.props.FetchClientOption();
    await this.props.FetchCrmInternalOption();
    //console.log("this is did mount",this.props.match.params);
    let id = this.props.match.params?.id
    this.findEndPoint()
    this.onSearchFormdata(this.state.pageNumber, this.state.pageSize)
    const params = {
      data: id
    }
    
    await this.props.FetchThirdPartyById(params)
    await this.props.FetchConnectorView(params)
  
  }
  findEndPoint =()=>{
    let recivedata= this.props.crmExternalDropDawn &&  this.props.crmExternalDropDawn.find(item=> item.endPointUrl);
    this.setState({
      ...this.state,
        getEndPoint:recivedata,
    })
  }
  getDataOnEditMode = async () => {
    const params = {
      data: this.props.thirdPartyById?.id
    }
    await this.props.FetchThirdPartyById(params)
   
    this.setState({
      ...this.state,
      agentState:{
        ...this.state.agentState,
        clientid:this.props.thirdPartyById.client_id,
        name:this.props.thirdPartyById.crmName,
        crmtype:this.props.thirdPartyById.crmType,
        apiendpoint:this.props.thirdPartyById.apiEndPoint,
        apitoken:this.props.thirdPartyById.apiToken,
        environment:this.props.thirdPartyById.environment,
        otherinformation:this.props.thirdPartyById.other_information



      }
    })
  }
  componentDidUpdate(prevProps, prevState) {
   
    if (this.props.thirdPartyById.id !== prevProps.thirdPartyById.id) {  
      this.getDataOnEditMode();
    }
    // if(this.props.connectorview.id !== prevProps.connectorview.id){
    //  const id=this.props.connectorview.id
    //   const params = {
    //     data: id
    //   }
    //    this.props.FetchConnectorView(params)
    // }
    if (this.state.searchText !== prevState.searchText) {
      if (this.state.searchText.length > 2) {
        this.onSearchFormdata(1, this.state.pageSize);
      } else if (this.state.searchText.length === 0) {
        this.onSearchFormdata(1, this.state.pageSize);
      }
    }

  }
  render() {
    const { count } = this.props?.paginationData2;
  
    return (
      <React.Fragment>
        {this.props.location.pathname === "/admin/listconnecters/addconnecters" &&
          <AddConnecters
            agentState={this.state.agentState}
            onDataInputFieldHnadleChange={this.onDataInputFieldHnadleChange}
            onDataInputFieldHnadleChangeshedule={this.onDataInputFieldHnadleChangeshedule}
            onCrmFieldHnadleChange={this.onCrmFieldHnadleChange}
            onAddRealEstate={this.onAddRealEstate}
            onHandleChangeSetup={this.onHandleChangeSetup}
            onTriggerActionHnadleChange={this.onTriggerActionHnadleChange}
            errors={this.state.errors}
            thirdPartyById={this.props.thirdPartyById}
            agentsDropDawn={this.props.agentsDropDawn}
            clientDropDawn={this.props.clientDropDawn}
            crmExternalDropDawn={this.props.crmExternalDropDawn}
            setupdata={this.props.crmInternalDropDawn}
            actionSetUp={this.state.actionSetUp}
            onActionHandleChange={this.onActionHandleChange}
            triggerResponse={this.props.triggerResponse}
          
          />
        }

        {this.props.location.pathname.includes("/admin/listconnecters/view") &&
          <ViewConnector
            agentState={this.state.agentState}
            onDataInputFieldHnadleChange={this.onDataInputFieldHnadleChange}
            onDataInputFieldHnadleChangeshedule={this.onDataInputFieldHnadleChangeshedule}
            onCrmFieldHnadleChange={this.onCrmFieldHnadleChange}
            onAddRealEstate={this.onAddRealEstate}
          
            errors={this.state.errors}
            thirdPartyById={this.props.thirdPartyById}
            agentsDropDawn={this.props.agentsDropDawn}
            clientDropDawn={this.props.clientDropDawn}
            crmExternalDropDawn={this.props.crmExternalDropDawn}
            setupdata={this.props.crmInternalDropDawn}
            actionSetUp={this.state.actionSetUp}
            triggerResponse={this.props.triggerResponse}
            connectorview={this.props.connectorview}
          />
        }
        {this.props.location.pathname === "/admin/listconnecters" &&
          (<>
                    {this.props.pending && <Loading/>}

            <ListConnecters
              connectorList={this.props.connectorList}
              onAddClick={this.onAddClick}
              onEditClick={this.onEditClick}
              searchHandleChange={this.searchHandleChange}
              searchText={this.state.searchText}
              openDeleteModal={this.openDeleteModal}
            />
            <PopupModal
              popupId="detele-popup"
              onPopup={this.onDelete}
              type={this.state.popupModalType ? this.state.popupModalType : ""}
              popupText={this.state.popupTitle}
              onCancel={this.onPopupCancel}
            />
            <Pagination           
              updateView={this.onSearchFormdata}
              pageNumber={this.state.pageNumber}
              pageSize={12}
              totalElements={count}
              totalPages={this.state.totallPage}            
              handlePageChangeValue={this.props.handlePageChangeValue}            
            />
          </>)

        }
      </React.Fragment>
    );
  }
}

//export default ManageSpecies;

const mapStateToProps = (state) => {
  let {
    AgentsReducer: {
      pending,
      error,
      thirdPartyReciveData,
      thirdPartyById,
      agentsDropDawn,
      crmExternalDropDawn,
      paginationData2,
      clientDropDawn,
      crmInternalDropDawn,
      actionBySetupId,
      triggerResponse,
      connectorList,
      connectorview
    }
  } = state;

  return {
    pending: pending,
    error: error,
    thirdPartyReciveData: thirdPartyReciveData,
    thirdPartyById: thirdPartyById,
    agentsDropDawn: agentsDropDawn,
    crmExternalDropDawn: crmExternalDropDawn,
    paginationData2: paginationData2,
    clientDropDawn:clientDropDawn,
    crmInternalDropDawn:crmInternalDropDawn,
    actionBySetupId:actionBySetupId,
    triggerResponse:triggerResponse,
    connectorList:connectorList,
    connectorview:connectorview
  };
};

ManageConnecters.propTypes = {
  AddConnecterData: PropTypes.func,
  FetchThirdList: PropTypes.func,
  FetchConnectorView:PropTypes.func,
  FetchAgentsOption: PropTypes.func,
  FetchCrmExternalOption: PropTypes.func,
  FetchRealAgentById: PropTypes.func,
  FetchThirdPartyById: PropTypes.func,
  FetchActionBySetup:PropTypes.func,
  UpdateThirdPartyData: PropTypes.func,
  UpdateRealAgentData: PropTypes.func,
  DeleteConnecter: PropTypes.func,
  FetchClientOption:PropTypes.func,
  FetchCrmInternalOption:PropTypes.func,
  FindTriggerData:PropTypes.func,
  FetchConnecterList:PropTypes.func,
  //history: PropTypes.object,
  pending: PropTypes.bool,
  error: PropTypes.object,
};
export default connect(mapStateToProps, {
  AddConnecterData,
  FetchThirdList,
  FetchConnectorView,
  FetchAgentsOption,
  FetchCrmExternalOption,
  FetchThirdPartyById,
  FetchActionBySetup,
  UpdateThirdPartyData,
  DeleteConnecter,
  FetchClientOption,
  FetchCrmInternalOption,
  FindTriggerData,
  FetchConnecterList,
})(ManageConnecters);