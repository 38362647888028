import React from 'react';
// import { Link } from "react-router-dom";
// import PopupModal from '../../../common/PopupModal';

const ListCrmAction = (props) => {
    
    return (


        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#" className="breadcrumb-link">Dashboard</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Crm Action</li>
                </ol>
            </nav>


            <div className="card">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h5 className="hed01">CRM Action</h5>
                        <input className="form-control serch_hed"
                         type="searchText"
                         value={props.searchText}
                         onChange={(e) => {
                           props.searchHandleChange(e);
                         }}
                          placeholder="Search.."
                         />
                        {/* <Link to='../admin/listcrmaction/addcrmaction' className="btn btn-info rounded"><i className="fal fa-plus"></i> Add</Link> */}
                    </div>

                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">CRM Name</th>
                                <th scope="col">Event Name</th>
                                <th scope="col">Event URL</th>                             
                                {/* <th scope="col">Action</th> */}
                            </tr>
                        </thead>
                        <tbody>

                            {props.internalList && props.internalList.length > 0 ? (
                                props.internalList.map((item, index) => (
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{item?.crmSetup.crmName}</td>
                                        <td>{item?.action_name}</td>
                                        <td>{item?.action_url}</td>
                                       
                                        <td> 
                                        {/* <PopupModal headerTitle={'Are you sure?'} buttonHtml={<i className="far fa-trash"></i>} footerHtml={
                                            <>
                                          <button className='btn btn-danger'  onClick={() =>props.openDeleteModal(item.id,1)} variant="primary">
                                            Delete
                                          </button></>
                                        }>
                                            <p>Want to delete !!</p>
                                        </PopupModal> */}
         
                                            {/* <a href='#'
                                             title="Remove" 
                                             onClick={() =>props.openDeleteModal(item.id,1)}
                                             data-toggle="modal"
                                             data-target="#detele-popup"                                           
                                            ><i className="far fa-trash"></i></a> */}
                                            
                                            </td></tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={5}>
                                        <div className="no-data text-center">
                                            {/* <img src="/assets/images/empty-box.svg" /> */}
                                            <h5>No Data Found</h5>
                                        </div>
                                    </td>
                                </tr>
                            )}





                        </tbody>
                    </table>

                </div>
            </div>



        </>
    );

}
export default ListCrmAction;