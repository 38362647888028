import React, { Component } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import {Link} from 'react-router-dom';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
//import profile_img from '../../img/profile-picture.png'

import jwt from 'jwt-decode'
//import LoginForm from "./LoginForm";
import {
    UserLogin,
    UserLogout
} from "../../actions/UserAction";
class Header extends Component {

    constructor() {
        super();
        this.state = {
            toggleSidebar:false,
            firstName:"",
            lastName:""
        }
    }

    toggleSidebar = () => {
        this.setState({
            toggleSidebar: !this.state.toggleSidebar
        }, ()=> {
            if(this.state.toggleSidebar){
                document.getElementsByTagName('body')[0].classList.add('sidebar-toggled')
                document.getElementById('accordionSidebar').classList.add('toggled')
            }else{
                document.getElementsByTagName('body')[0].classList.remove('sidebar-toggled')
                document.getElementById('accordionSidebar').classList.remove('toggled')
            }           
        })        
    }


    styles = {
        "backgroundColor": "#565555",
    }

    toLogOut =()=>{      
       // toast.success("Logout successfully!");
              localStorage.clear();
                window.location.assign("/admin/login")
                toast.success("Logout successfully!");
    }
   

     componentDidMount(){
        this.decodeToken();
    const user = jwt(localStorage.getItem("token"));
  
     }
    decodeToken=()=>{
        const user = jwt(localStorage.getItem("token"));
        //  let firstName=JSON.stringify(user.result.rows[0].firstName)
        //  let lastName=JSON.stringify(user.result.rows[0].lastName)
        //  let newFirstName=firstName.split('"').join('')
        // let newLastName= lastName.split('"').join('')
         this.setState({
             ...this.state,
             firstName:user.firstName,
             lastName:user.lastName
         })
        
    }
    render() {
      
        return (
            <React.Fragment>
               <nav className="navbar navbar-expand-lg bg-white fixed-top">
                <a className="navbar-brand" href="#"><img src="../../../assets/images/logo.png" alt="" /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse " id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto navbar-right-top align-items-center">

                        <li className="nav-item">
                            <span className="user_name" href="#" >
                                {(this.state.firstName.charAt(0))}  {(this.state.lastName.charAt(0))}
                            </span> {this.state.firstName+" " +this.state.lastName}
                        </li>
						<li className="nav-item">
                            <a className="nav-link" href="#" onClick={this.toLogOut} >Logout</a>
                        </li>
                    </ul>
                </div>
            </nav>
            </React.Fragment>
        );
    }
}



const mapStateToProps = (state) => {
    let {
        UserReducer: { pending, error, loginData },
    } = state;

    return { pending, error, loginData };
};

Header.propTypes = {
    UserLogin: PropTypes.func,
    UserLogout:PropTypes.func,
    loginData: PropTypes.object,
    pending: PropTypes.bool,
    error: PropTypes.object,
};

export default connect(mapStateToProps, {
    UserLogin,
    UserLogout
})(Header);

//export default Header;