import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import {
    FetchHistory,FetchHistoryDetails,FetchConnectorDropDawn
} from "../../../../actions/RealEstateAction"
import { propTypes } from "react-bootstrap/esm/Image";
import ListGlobleLog from "./ListGlobleLog";
 import LogHistory from "./LogDetails";
// import validateInternalCrmForm from "../../../validation_rules/InternalCrmFormRule";
import Pagination from "../../../common/Pagination";
import PopupModal from "../../../common/PopupModal";
import Loading from "../../../common/Loading";
import { toast } from "react-toastify";
const date = new Date();
const futureDate = date.getDate();
date.setDate(futureDate);
const modifiedDate = date.toLocaleDateString('en-CA');
class ManageGlobleConnect extends Component {
  constructor() {
    super();
  }
  state = {
  mode: "List",
  errors: {},
  pageNumber:1,
  pageSize:50,
  totalElements: 50,
  searchText: "",
  popupModalType: 0,
  popupTitle: "",
  deleteId:"",
  totallPage: "",
  getEndPoint:{},
  historyDetail:{},
  connectorid:"",
  //startdate: new Date(Date.now() - 864e5),
  startdate:"",
  enddate:new Date()
  
  }



  searchHandleChange = (e) => {
    this.setState({ ...this.state, searchText: e.target.value }
      );};

  onSearchFormdata =async (pageNumber, pageSize) => {
    this.setState({
      ...this.state,
      pageNumber: pageNumber,
      pageSize: pageSize,
      //goToPageNumber: pageNumber,
     
    });

   

    const params = {
      page: pageNumber,
      size: pageSize,
      searchText: this.state.searchText,
      connectorid:this.state.connectorid,
      //startdate:this.state.startdate,
      startdate:moment(this.state.startdate).format("yyyy-MM-DD"),
     // enddate:this.state.enddate
     enddate:moment(this.state.enddate).format("yyyy-MM-DD"),
     
    };
    // };
    // if(this.state.connectorid !==""  && this.state.startdate !=="" && this.state.enddate !==""){
     if(this.props?.paginationData3?.posteddata){
        const{connectorid,startdate,enddate}=this.props?.paginationData3?.posteddata
      const params = {
        page: this.state.pageNumber,
        size: this.state.pageSize,
        searchText: this.state.searchText,
        connectorid:connectorid,
        //startdate:startdate,
        startdate:moment(startdate).format("yyyy-MM-DD"),
       // enddate:enddate
       enddate:moment(enddate).format("yyyy-MM-DD"),
       
      };
        await this.props.FetchHistory(params);
        const { count, size } = this.props.paginationData3;
        let totalPage = Math.ceil(+count / +size)
        this.setState({
          connectorid:connectorid,
          totallPage: totalPage,
          startdate:new Date(startdate),
          enddate:new Date(enddate),
        });
     
      }
    // }
   
   await this.props.FetchConnectorDropDawn(params)
    
  };


  onEditClick = async (reciveId) => {
    const params = {
      data: reciveId
    }
    await this.props.FetchHistoryDetails(params)
    this.setState({
      ...this.state,
      historyDetail: this.props.logHistoryDetails
    })
  }

  

  async componentDidMount() {
    let id=this.props.match.params?.id
    
     this.onSearchFormdata(this.state.pageNumber, this.state.pageSize)
    const params1 = {
      data:id
    }
//this.props.FetchConnectorDropDawn()
     if(id){
      this.props.FetchHistoryDetails(params1)
     }
   

  }


  getDataOnEditMode =async () => {
    const params = {
      data: this.props.logHistoryDetails?.id
    }
    await this.props.FetchHistoryDetails(params)
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.logHistoryDetails.id !== prevProps.logHistoryDetails.id) {

      this.getDataOnEditMode();
    }
    if (this.state.searchText !== prevState.searchText) {
      if (this.state.searchText.length > 2) {
        this.onSearchFormdata(1, this.state.pageSize);
      } else if (this.state.searchText.length === 0) {
        this.onSearchFormdata(1, this.state.pageSize);
      }
    }

  }

  onHandleChangeAdvSerch = (e)=>{
    this.setState({
      ...this.state,
      [e.target.name]:e.target.value
    })
  }
  onHandleChangeStartDate = (date)=>{
    this.setState({
      ...this.state,
      startdate: date
    })
  }
  onHandleChangeEndDate = (date)=>{
    this.setState({
      ...this.state,
      enddate: date
    })
  }
  getHistoryList =async()=>{
    const params = {
      page: this.state.pageNumber,
      size: this.state.pageSize,
      searchText: this.state.searchText,
      connectorid:this.state.connectorid,
      //startdate:this.state.startdate,
      startdate:moment(this.state.startdate).format("yyyy-MM-DD"),
     // enddate:this.state.enddate
     enddate:moment(this.state.enddate).format("yyyy-MM-DD"),
     
    };
    if(this.state.connectorid !=="" && this.state.startdate !=="" && this.state.enddate !==""){
      await this.props.FetchHistory(params);
      const { count, size } = this.props.paginationData3;
      let totalPage = Math.ceil(+count / +size)
      this.setState({
        totallPage: totalPage,
      });
    } else{
      toast.warn("Please select Connector, start date and end date")
    }
  }

  ogBackTolIST=async(e)=>{
    //window.location.href ="/admin/listGlobleLog";
   
    const{connectorid,startdate,enddate}=this.props.paginationData3?.posteddata
  
    const params = {
        page: this.state.pageNumber,
        size: this.state.pageSize,
        searchText:this.state.searchText,
        connectorid:connectorid,
        //startdate:startdate,
        startdate:moment(startdate).format("yyyy-MM-DD"),
        //enddate:enddate,
        enddate:moment(enddate).format("yyyy-MM-DD"),
       
      };
      const { count, size } = this.props.paginationData3;
      let totalPage = Math.ceil(+count / +size)
      this.setState({
        ...this.state,
        connectorid:connectorid,
        totallPage: totalPage,
        startdate:startdate,
      // startdate:moment(startdate).format("yyyy-MM-dd"),
        enddate:enddate,
      })
      await this.props.FetchHistory(params);
   this.props.location.push("/admin/listGlobleLog")
    
   
  }
  render() {
   const { count, size } = this.props?.paginationData3;
   console.log("this is log history........",this.state);
    return (
      <React.Fragment>
        {this.props.location.pathname === "/admin/listGlobleLog" && ( <>
          {this.props.pending  && <Loading/>}
          <ListGlobleLog
          logHistoryData={this.props.logHistoryData}
          onEditClick={this.onEditClick}
          searchHandleChange={this.searchHandleChange}
          searchText={this.state.searchText}
          connectorDropDawn={this.props.connectorDropDawn}
          connectorid={this.state.connectorid}
          startdate={this.state.startdate}
          enddate={this.state.enddate}
          onHandleChangeAdvSerch={this.onHandleChangeAdvSerch}
          onHandleChangeStartDate={this.onHandleChangeStartDate}
          onHandleChangeEndDate={this.onHandleChangeEndDate}
          getHistoryList={this.getHistoryList}
          />
 
          <PopupModal/>
          {count > 50 &&(
       <Pagination
            updateView={this.onSearchFormdata}
            pageNumber={this.state.pageNumber}
            pageSize={size}
            totalElements={count}
            totalPages={this.state.totallPage}
            handlePageChangeValue={this.props.handlePageChangeValue}
       
     />)}
     </>)
        }
         {this.props.location.pathname.includes("/admin/logDetail/view/") &&( <>
          { this.props.pendingHistory && <Loading/>}
         <LogHistory
         logHistoryDetails={this.props.logHistoryDetails}
         ogBackTolIST={this.ogBackTolIST}
         posteddata= {this.props.paginationData3?.posteddata}
         /> </>)}
      </React.Fragment>
    );
  }
}

//export default ManageSpecies;

const mapStateToProps = (state) => {
  let {
    AgentsReducer: {
      pending,
      pendingHistory,
      error,
      logHistoryData,
     logHistoryDetails,
     connectorDropDawn,
    //   agentsDropDawn,
    //   crmInternalDropDawn,
    paginationData3
    }
  } = state;

  return {
    pending: pending,
    pendingHistory:pendingHistory,
    error: error,
    logHistoryData: logHistoryData,
    logHistoryDetails: logHistoryDetails,
    connectorDropDawn:connectorDropDawn,
    // agentsDropDawn:agentsDropDawn,
    // crmInternalDropDawn:crmInternalDropDawn,
     paginationData3: paginationData3
  };
};

ManageGlobleConnect.propTypes = {
    FetchHistory:PropTypes.func,
    FetchConnectorDropDawn:PropTypes.func,
    FetchHistoryDetails:PropTypes.func,
  //history: PropTypes.object,
  pending: PropTypes.bool,
  pendingHistory:PropTypes.bool,
  error: PropTypes.object,
};
export default connect(mapStateToProps, {
    FetchHistory,
    FetchHistoryDetails,
    FetchConnectorDropDawn
})(ManageGlobleConnect);
