//import { isEmail, isLength } from "validator";
import messages from "../common/messages";

export default function validateConnectorForm({
    clientid,	
    setupid,
    actionid,
    triggersetupid,
    triggeractionid }) {
    let errors = {};
    if (!clientid) {
        errors.clientid = messages["clientid.required"];
    } 
 
      if (!setupid) {
        errors.setupid = messages["setupid.setupid"];
    }

    if (!actionid) {
        errors.actionid = messages["actionid.required"];
    }

    if (!triggersetupid) {
        errors.triggersetupid = messages["triggersetupid.required"];
    }
    if (!triggeractionid) {
        errors.triggeractionid = messages["triggeractionid.required"];
    }
 
    return errors; 
}